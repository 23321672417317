import { Color } from "../enums/Color"
import { Font } from "../enums/Font"
import { commonHeader } from "./CommonHeader"

export const fullHeader = {
    mobile: {
        ...commonHeader.mobile,
        header: {
            background: Color.Blue,
            marginTop: "0%",
            marginLeft: "0%",
            width: "100%",
            height: "120.77vw",
            opacity: "1",
            position: "relative"
        },
        recruitersButton: {
            position: "absolute" as "absolute",
            width: "90.33vw",
            height: "12.07vw",
            marginLeft: "5%",
            marginTop: "79.22vw",
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.DeepBlack,
            border: "none",
            borderRadius: "2.41vw",
            fontSize: "4.34vw",
            WebkitTapHighlightColor: "transparent"
        },
        jobSeekersButton: {
            position: "absolute" as "absolute",
            width: "90.33vw",
            height: "12.07vw",
            marginLeft: "5%",
            marginTop: " 64.73vw",
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,
            border: "none",
            borderRadius: "2.41vw",
            cursor: "pointer",
            fontSize: "4.34vw",
            WebkitTapHighlightColor: "transparent"
        },
        heading: {
            position: "relative" as "relative",
            left: "50%",
            transform: "translate(-50%, 0%)",
            width: "70.28vw",
            marginTop: "11.835vw",
            marginBottom: "0",
            textAlign: "center" as "center",
            fontWeight: "bold" as "bold",
            fontSize: "6.03vw",
            letterSpacing: "0px",
            color: Color.White,
            opacity: "1",
            moveDown: {
                marginTop: "27.54vw"
            }
        },
        text: {
            position: "relative" as "relative",
            left: "50%",
            transform: "translate(-50%, 0%)",
            width: "90.33vw",
            marginTop: "2.41vw",
            textAlign: "center" as "center",
            fontSize: "2.90vw",
            letterSpacing: "0px",
            color: Color.White,
            opacity: "1",
            fontFamily: Font.Poppins
        }
    },
    desktop: {
        ...commonHeader.desktop,
        header: {
            height: "28.64vw",
        },
        recruitersButton: {
            position: "relative" as "relative",
            marginRight: "1.04vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "14.58vw",
            fontSize: "1.04vw",
            borderRadius: "0.52vw",
            height: "3.64vw"
        },
        jobSeekersButton: {
            position: "relative" as "relative",
            marginRight: "2.60vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "14.58vw",
            fontSize: "1.04vw",
            borderRadius: "0.52vw",
            height: "3.64vw"
        },
        heading: {
            width: "57.03vw",
            marginTop: "4.52vw",
            fontSize: "2.5vw",
            moveDown: {
                marginTop: "4.52vw"
            }
        },
        text: {
            width: "49.27vw",
            height: "12.8vh",
            textAlign: "center" as "center",
            fontFamily: "Poppins",
            fontSize: "0.83vw",
            letterSpacing: "0px",
            color: Color.White,
            opacity: "1",
            marginTop: "0.78vw"
        }
    }
}
