import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CandidateProfileModel, CandidateProfileValidation } from "../../models/CandidateProfileModel";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getCandidateProfile, postCandidateProfile } from "../../services/CandidateService";
import { Button } from "../Shared/Buttons/Button";
import { TextInput } from "../Shared/Inputs/TextInput";
import { ReactComponent as CloseCircle } from "../../assets/svg/close-circle.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { Sector } from "../../models/Sector";
import { getAllSectors } from "../../services/JobService";
import useErrorPage from "../../hooks/useErrorPage";
import { useAlert } from "../../hooks/useAlert";
import { ResponseModel } from "../../models/ResponseModel";
import { Candidate } from "../../models/Candidate";
import { SingleSelect } from "../Shared/SingleSelect";
import { Option, SelectInputs } from "../../models/SelectInputs";
import { getProvinces } from "../../services/HomeService";
import { Province } from "../../models/Province";
import { validateEmail, validateName, validatePhoneNumber } from "../../helpers/Common";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedCandidate } from "../../models/LoggedCandidate";
import { MyInformationLoader } from "../Loaders/MyInformationLoader";

interface Props {
    candidate: LoggedCandidate;
    setCandidate(candidate: Candidate): void;
    getCompletedPercentage(): void;
}

const intialFormInputs: CandidateProfileModel = {
    candidateId: 0,
    forename: "",
    surname: "",
    recentJobTitle: "",
    homeTown: "",
	provinceId: 0,
    location: "",
    personalSummary: "",
    additionalQualifications: "",
    email: "",
    accountVerified: false,
    mobileNumber: "",
    address: "",
    preferredSectors: []
}

const initialValidation: CandidateProfileValidation = {
    forename: {},
    surname: {},
    recentJobTitle: {},          
    email: {},
    mobileNumber: {}
}

export const MyInformation: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("myInformation"));
    const [candidateProfile, setCandidateProfile] = useState<CandidateProfileModel>(intialFormInputs);
    const [validation, setValidation] = useState<CandidateProfileValidation>(initialValidation);
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [hoveredSectorId, setHoveredSectorId] = useState<number>(0);
    const [hoveredNewSector, setHoveredNewSector] = useState<boolean>(false);
    const [hoveredNewSectorIndex, setHoveredNewSectorIndex] = useState<number>(-1);
    const [sectorSearchKeyword, setSectorSearchKeyword] = useState<string>("");
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [filteredSectors, setFilteredSectors] = useState<Sector[]>([]);
    const [provinceOptions, setProvinceOptions] = useState<SelectInputs>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [toErrorPage] = useErrorPage();
    const { Alert, openAlert } = useAlert();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        getMyInformation();
    }, []);

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("myInformation"));
    }, [isMobile]);

    async function getMyInformation() {
        setLoading(true);
        await getCandidateProfile(props.candidate.candidateId, props.candidate.token)
        .then((profileInformation: CandidateProfileModel) => {
            setCandidateProfile(profileInformation);
            setLoading(false);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    async function getAllSectorList() {
        await getAllSectors()
        .then((sectorList: Sector[]) => {
            var notPreferredSectors = sectorList.filter(x => !candidateProfile.preferredSectors.some(y => y.sectorId === x.sectorId));
            setSectors(notPreferredSectors);
            sortSectorList(notPreferredSectors);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    async function getProvinceList() {
        await getProvinces()
        .then((provinceList: Province[]) => {
            const tempOptions = new Array<Option>();                 
            provinceList.forEach((province) => {                           
                let option: Option = {
                    value: province.provinceId,
                    option: province.provinceName,
                    checked: province.provinceId === candidateProfile.provinceId
                }           
                tempOptions.push(option);
            });
            let defaultSelect = "Select the Province";
            if(candidateProfile.provinceId && candidateProfile.provinceId !== 0) {
                defaultSelect = provinceList.find(x => x.provinceId === candidateProfile.provinceId)!.provinceName;
            }
            let tempProvinceOptions: SelectInputs = {
                default: defaultSelect,
                options: tempOptions
            }
            setProvinceOptions(tempProvinceOptions);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    const sortSectorList = (sectorList: Sector[]) => {
        const sortedList = sectorList.sort((a, b) => a.sectorName.localeCompare(b.sectorName));
        setFilteredSectors(sortedList);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setCandidateProfile((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name]!.invalid) {
            validateInput(name, value);
        }
    }

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setCandidateProfile((values: any) => ({ ...values, [name]: value }));
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const validateInput = (name: string, value: string) => {        
        if(name === "forename" || name === "surname") {
            setValidation((values: any) => ({...values, [name]: validateName(name, value, 50)}));            
        }
        if(name === "recentJobTitle" && value !== "") {            
            if (value.length > 150) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Exceeds maximum allowable character length (150)"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "email") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Email is required"}}));
            } 
            else if (!validateEmail(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid email address!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        } 
        if(name === "mobileNumber" && value !== "") {                 
            if(!validatePhoneNumber(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid Mobile number!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }       
    } 

    const validateForm = () => {
        let formValidation: CandidateProfileValidation = {
            forename: {valid: true},
            surname: {valid: true},
            email: {valid: true}
        }     
        formValidation.forename = validateName("forename", candidateProfile.forename, 50);        
        formValidation.surname = validateName("surname", candidateProfile.surname, 50);
        if(candidateProfile.recentJobTitle !== "" && candidateProfile.recentJobTitle.length > 150) {
            formValidation.recentJobTitle = {valid: false, invalid: true, invalidMessage: "Exceeds maximum allowable character length (150)"};
        } 
        if(candidateProfile.email === "") {
            formValidation.email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        } 
        else if(!validateEmail(candidateProfile.email)) {
            formValidation.email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        } 
        if(candidateProfile.mobileNumber !== "" && !validatePhoneNumber(candidateProfile.mobileNumber)) {
            formValidation.mobileNumber = {valid: false, invalid: true, invalidMessage: "Invalid Mobile number!"};
        } 
        setValidation(formValidation);        
    }

    const handleEditClick = () => {
        setEditEnable(true);   
        validateForm();     
        if(sectors.length === 0) {
            getAllSectorList();
        }
        if(!provinceOptions) {
            getProvinceList();
        }   
    }

    const removeSector = (index: number) => {
        let tempSectors = [...candidateProfile?.preferredSectors];
        let removedSector = tempSectors[index];
        tempSectors.splice(index, 1);
        let tempCandidateProfile = { ...candidateProfile, preferredSectors: tempSectors };
        // Add the removed sector to sector List
        let allSectors = [removedSector, ...sectors];
        setSectors(allSectors);
        sortSectorList(allSectors);
        setCandidateProfile(tempCandidateProfile);    
    }

    const addSector = (sector: Sector) => {
        let tempSectors = [...candidateProfile?.preferredSectors];
        if (!tempSectors.some(s => s.sectorId === sector.sectorId)) {
            tempSectors.push(sector);
            let tempCandidateProfile = { ...candidateProfile, preferredSectors: tempSectors };
            let tempFilteredSectors = sectors.filter(x => x.sectorId !== sector.sectorId);
            setSectors(tempFilteredSectors);
            sortSectorList(tempFilteredSectors);
            setCandidateProfile(tempCandidateProfile);
        }        
    }

    const filterSectors = (keyword: string) => {
        keyword = keyword.trim();
        setSectorSearchKeyword(keyword);
        var tempSectors = sectors.filter(x => x.sectorName.toUpperCase().includes(keyword.toUpperCase()));
        setFilteredSectors(tempSectors);
    }

    const setProvince = (provinceId: number) => {        
        setCandidateProfile((values: any) => ({ ...values, ["provinceId"]: provinceId }));
    }

    async function handleSaveClick(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const validInputCount = Object.keys(validation).filter(key => validation[key]!.valid).length;
        const invalidInputCount = Object.keys(validation).filter(key => validation[key]!.invalid).length;        
        if(validInputCount >= 3 && invalidInputCount === 0) { 
            await postCandidateProfile(candidateProfile, props.candidate.token)
            .then((response: ResponseModel) => {
                if (response.status) {
                    openAlert("success", response.message);
                    authContext.setLoggedCandidate(response.data.loggedCandidate);
                    props.setCandidate(response.data.candidate);
                    props.getCompletedPercentage();
                    getMyInformation();
                    setEditEnable(false);
                }
                else {
                    openAlert("error", response.message);
                }
            })
            .catch((error) => {
                toErrorPage(error);
            });
        }
        else {
            validateForm();
            openAlert("error", "Some mandatory fields are invalid.");
        }          
    }

    const handleDiscardClick = () => {
        setEditEnable(false);
        getMyInformation();
    }

    return (
        <>
            <div style={{...styles.wrapper, ...styles.titleWrapper}}>
                <div style={styles.title}>{"My Information"}</div>
                {!editEnable && <Button type="button" style={styles.borderButton} onClick={handleEditClick}>{"EDIT INFO"}</Button>} 
            </div>
            {loading ? <MyInformationLoader/> :
            <form style={styles.formWrapper} onSubmit={handleSaveClick}>
                <p style={styles.subTitle}>{"Basic Info"}</p>
                <div style={styles.swiftWrapper}>
                    <div style={{ ...styles.inputWrapper, ...styles.inputRightMargin }}>
                        <label style={styles.label}>{"Full Name"}</label>
                        <div style={styles.wrapper}>
                            <TextInput
                                inputType={"text"}
                                name={"forename"}
                                value={candidateProfile?.forename}
                                styles={{ ...styles.textInput, ...styles.nameRightMargin }}
                                handleChange={handleInputChange}
                                handleBlur={handleInputBlur}
                                disabled={!editEnable}                                
                                validation={validation.forename} 
                            />
                            <TextInput
                                inputType={"text"}
                                name={"surname"}
                                value={candidateProfile?.surname}
                                styles={{ ...styles.textInput, ...styles.nameLeftMargin }}
                                handleChange={handleInputChange}
                                handleBlur={handleInputBlur}
                                disabled={!editEnable}                                
                                validation={validation.surname} 
                            />
                        </div>
                    </div>
                    <div style={{ ...styles.inputWrapper, ...styles.inputLeftMargin }}>
                        <label style={styles.label}>{"Designation"}</label>
                        <TextInput
                            inputType={"text"}
                            name={"recentJobTitle"}
                            value={candidateProfile?.recentJobTitle}
                            styles={styles.textInput}
                            handleChange={handleInputChange}
                            handleBlur={handleInputBlur}
                            disabled={!editEnable}                            
                            validation={validation.recentJobTitle}    
                        />
                    </div>
                </div>
                <div style={styles.locationWrapper}>
                    <div style={{ ...styles.inputWrapper, ...styles.inputLeftMargin }}>
                        {editEnable ? <div style={styles.locationEditWrapper}>
                            <div style={styles.locationEditWrapper.item}>
                                <label style={styles.label}>{"Home Town"}</label>
                                <TextInput
                                    inputType={"text"}
                                    name={"homeTown"}
                                    value={candidateProfile?.homeTown}
                                    styles={styles.textInput}
                                    handleChange={handleInputChange}
                                />
                            </div>
                            {provinceOptions && <div style={styles.locationEditWrapper.item}>
                                <label style={styles.label}>{"Province"}</label>
                                <SingleSelect styles={styles.provinceSelect} selectInputs={provinceOptions} getSelected={setProvince} />
                            </div>}
                        </div>
                        : <>
                            <label style={styles.label}>{"Location"}</label>
                            <TextInput
                                inputType={"text"}
                                name={"location"}
                                value={candidateProfile?.location}
                                styles={styles.textInput}
                                handleChange={handleInputChange}
                                disabled={true}
                            />
                        </>}
                    </div>
                    <div style={{ ...styles.inputWrapper, ...styles.inputRightMargin }}>
                        <label style={styles.label}>{"Personal Summary"}</label>
                        <textarea
                            name={"personalSummary"}
                            value={candidateProfile?.personalSummary}
                            style={{ ...styles.textInput, ...styles.textAreaInput, ...(!editEnable && styles.editDisabled)}}
                            onChange={handleTextareaChange}
                            disabled={!editEnable}
                            className="scrollArea"
                        />
                    </div>
                </div>
                <hr style={styles.line} />
                <p style={styles.subTitle}>{"Career Info"}</p>
                <div style={styles.inputWrapper}>
                    <label style={styles.label}>{"Additional Qualifications"}</label>
                    <TextInput
                        inputType={"text"}
                        name={"additionalQualifications"}
                        value={candidateProfile?.additionalQualifications}
                        styles={styles.textInput}
                        handleChange={handleInputChange}
                        disabled={!editEnable}
                    />
                </div>
                <div style={{...styles.inputWrapper, ...(!editEnable && styles.editDisabled)}}>
                    <label style={styles.label}>{"Preferred Sectors"}</label>
                    <div style={styles.sectorContainer}>
                        {candidateProfile.preferredSectors && candidateProfile?.preferredSectors.map((sector, index) =>
                            <div key={index}
                                style={styles.sector}
                                onMouseEnter={() => setHoveredSectorId(sector.sectorId)}
                                onMouseLeave={() => setHoveredSectorId(0)}>
                                {sector.sectorName}
                                {editEnable && hoveredSectorId === sector.sectorId && <CloseCircle style={styles.closeIcon} onClick={() => removeSector(index)} />}
                            </div>
                        )}
                        {editEnable && <div 
                            style={{ ...styles.sector, ...styles.allSectorWrapper }} 
                            onMouseEnter={() => setHoveredNewSector(true)} 
                            onMouseLeave={() => setHoveredNewSector(false)}
                            onClick={() => setHoveredNewSector(!hoveredNewSector)}    
                        >
                            + Add New
                            <div style={styles.hoverWrapper}>
                                <div style={hoveredNewSector ? styles.hover : styles.leave}>
                                    <div onClick={e => {e.stopPropagation()}}>
                                        <input type="text" style={styles.search} placeholder={"Search"} onChange={(e) => filterSectors(e.target.value)} />
                                        {sectorSearchKeyword === "" && <SearchIcon style={styles.search.icon} />}
                                    </div>
                                    <div className="scrollArea" style={styles.scrollArea}>
                                        {filteredSectors.length > 0 && filteredSectors.map((sector, index) => <React.Fragment key={index}>
                                            <div style={{ ...styles.option, ...(hoveredNewSectorIndex === index && styles.option.hover) }} onClick={() => addSector(sector)} onMouseEnter={() => setHoveredNewSectorIndex(index)} onMouseDown={() => { setHoveredNewSectorIndex(-1); }}>
                                                {sector.sectorName}
                                            </div>
                                            <hr style={{ ...styles.bottomBorder, ...((hoveredNewSectorIndex === index || hoveredNewSectorIndex === index + 1 || sectors.length === index + 1) && styles.hoveredBorder) }} />
                                        </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <hr style={styles.line} />
                <p style={styles.subTitle}>{"Contact Info"}</p>
                <div style={styles.swiftWrapper}>
                    <div style={{ ...styles.inputWrapper, ...styles.inputRightMargin }}>
                        <label style={styles.label}>{isMobile ? "Email" : "Email Address"}</label>
                        <TextInput
                            inputType={"text"}
                            name={"email"}
                            value={candidateProfile?.email}
                            styles={styles.textInput}
                            handleChange={handleInputChange}
                            handleBlur={handleInputBlur}
                            disabled={!editEnable}
                            validation={validation.email}                            
                        />
                    </div>
                    <div style={{ ...styles.inputWrapper, ...styles.inputLeftMargin }}>
                        <label style={styles.label}>{"Mobile Number"}</label>
                        <TextInput
                            inputType={"tel"}
                            name={"mobileNumber"}
                            value={candidateProfile?.mobileNumber}
                            styles={styles.textInput}
                            handleChange={handleInputChange}
                            handleBlur={handleInputBlur}
                            disabled={!editEnable}
                            validation={validation.mobileNumber}                            
                        />
                    </div>
                </div>
                <div style={styles.inputWrapper}>
                    <label style={styles.label}>{"Address"}</label>
                    <TextInput
                        inputType={"text"}
                        name={"address"}
                        value={candidateProfile?.address}
                        styles={styles.textInput}
                        handleChange={handleInputChange}
                        disabled={!editEnable}
                    />
                </div>
                {editEnable && <div style={{...styles.wrapper, ...styles.saveWrapper}}>                    
                    <Button type="submit" style={styles.saveButton}>{isMobile ? "SAVE CHANGES" : "SAVE"}</Button>
                    <Button type="button" style={styles.discardButton} onClick={handleDiscardClick}>{"DISCARD"}</Button>
                </div>}
            </form>}
            <Alert/>
        </>
    );
}