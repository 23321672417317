import { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { VerticalJobDetailCard } from '../components/Shared/VerticalJobDetailCard';
import { JobViewLoader } from '../components/Loaders/JobViewLoader';
import { Footer } from '../components/Shared/Footer';
import { Header } from '../components/Shared/Header';
import { JobViewTexts as Texts } from '../helpers/LayoutTexts';
import { ReactComponent as DropDownIcon } from '../assets/svg/drop_down.svg';
import { JobView } from '../models/JobView';
import { StyleContext } from '../providers/StyleContextProvider';
import { getPublishedJob, getRelatedJobs, updateJobRedirect, updateJobViewCount } from '../services/JobService';
import { JobApply } from '../components/JobView/JobApply';
import { Share } from '../components/Shared/Share';
import { JobOrganizationCard } from '../components/Shared/JobOrganizationCard';
import { HorizontalBanner } from '../components/Shared/HorizontalBanner';
import { VerticalBanner } from '../components/Shared/VerticalBanner';
import { HorizontalJobDetailCard } from '../components/Shared/HorizontalJobDetailCard';
import { AuthContext } from '../providers/AuthContextProvider';
import useErrorPage from '../hooks/useErrorPage';
import useSaveJob from '../hooks/useSaveJob';
import { JobSectors } from '../components/JobView/JobSectors';
import { Helmet } from 'react-helmet-async';
import { ShortJobCardList } from '../components/Shared/ShortJobCardList';
import { LoggedCandidate } from '../models/LoggedCandidate';

export const JobViewPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const params = useParams();
    const jobId = +params.jobId!;
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("jobViewPage"));
    const [isJobAdExpand, setIsJobAdExpand] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [jobView, setJobView] = useState<JobView>();
    const [isBannerOffed, setIsBannerOffed] = useState<boolean>(false);
    const [jobVideoUrl, setJobVideoUrl]= useState<string>();
    const horizontalCardEnablePoint: number = 600;
    const [horizontalCardEnable, setHorizontalCardEnable] = useState<boolean>(false);
    const stateRef = useRef<boolean>();
    stateRef.current = horizontalCardEnable;
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const relatedJobCount: number = isMobile ? 10 : 24;
    const [toErrorPage] = useErrorPage();
    const {saveCandidateJob, isSavedJob} = useSaveJob();
    const [isSaved, setIsSaved] = useState<boolean>(isSavedJob(jobId));
    const applyEnable = searchParams.get("applyEnable") !== 'false';

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("jobViewPage"));
    }, [isMobile]);

    useEffect(() => {
        getJobView();
        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        }
    }, [jobId]);

    async function getJobView() {
        await getPublishedJob(jobId)
        .then((publishedJob: JobView) => {
            if (publishedJob) {
                setJobView(publishedJob);
                setIsBannerOffed(publishedJob.organization.isBannerOffed);
                setLoading(false);
                updateViewCount(jobId);
                if(publishedJob.jobItem.videoUrl) {
                    const embedUrl = publishedJob.jobItem.videoUrl.replace("watch?v=", "embed/");
                    setJobVideoUrl(embedUrl);
                }
            } else {
                navigate('/jobs');
            }
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    async function getRelatedJobList() {
        return getRelatedJobs(jobId, relatedJobCount);        
    }

    const updateViewCount = (jobId: number) => {
        updateJobViewCount(jobId);
    }

    const onScroll = () => {
        if ((window.scrollY < horizontalCardEnablePoint) && stateRef.current) {
            setHorizontalCardEnable(false);
        } else if ((window.scrollY > horizontalCardEnablePoint) && !stateRef.current) {
            setHorizontalCardEnable(true);
        }
    }

    const handleJobAdButtonClick = () => {
        setIsJobAdExpand((prev) => !prev);
    }

    const handleApplyButtonClick = () => {
        if (jobView?.isRedirected) {
            updateJobRedirect(jobView?.jobId)
            .then((response: boolean) => { })
            .catch((error) => {
                toErrorPage(error);
            });
            window.open(jobView?.redirectingUrl, '_blank');
        } else if (jobView?.jobItem.isPreApplicationFormEnabled) {
            navigate("/jobs/preapplicationform/" + jobView?.jobId);
        } else {
            if (candidate === null) {
                const params = { callbackUrl: "/jobs/apply/" + jobView?.jobId, message: "Please login or register to apply this job." };
                navigate({
                    pathname: '/candidates/login',
                    search: `?${createSearchParams(params)}`
                });
            } else {
                navigate("/jobs/apply/" + jobView?.jobId);
            }
        }
    }

    const saveJob = (jobId: number) => {
        saveCandidateJob(jobId, isSaved);
        setIsSaved(!isSaved);
    }
   
    return (
        <>
            <Header styleSheet="jobViewHeader" searchEnable={false} jobTitle={jobView?.jobTitle} organization={jobView?.organization.organizationName} isDisabilityFriendly={jobView?.jobItem.disabilityFriendly} backButtonText={"Back"} jobSaved={isSaved} backButtonEnable={true} />
            {loading ? <JobViewLoader styles={styles} /> :
                <div>
                    <Helmet>
                        <title>{jobView?.jobTitle}</title>
                        <meta name="description" content={jobView?.jobTitle} />
                        <meta name="keywords" content={jobView?.metaKeywords} />
                        <meta name="og:title" content={jobView?.jobTitle} />
                    </Helmet>
                    {horizontalCardEnable && <HorizontalJobDetailCard styles={styles.horizontalDetailCard} job={jobView!.jobItem} isSaved={isSaved} applyEnable={applyEnable} handleApplyButton={handleApplyButtonClick} saveJob={saveJob} />}
                    <div style={styles.baseWrapper}>
                        <div style={styles.leftWrapper}>
                            {/* This is loading only mobile platform */}
                            <div style={styles.mobileDetailCard}>
                                <VerticalJobDetailCard job={jobView!.jobItem} isSaved={isSaved} applyEnable={applyEnable} education={jobView!.education} experience={jobView!.experience} salaryRange={jobView!.salaryRange} handleApplyButtonClick={handleApplyButtonClick} saveJob={saveJob} />
                            </div>
                            <div style={styles.description}>
                                <div  className="jobviewDescription" dangerouslySetInnerHTML={{ __html: jobView?.jobInfo! }}/>
                            </div>
                            {jobView?.jobHasImage && <div style={{ ...styles.jobAdContainer, ...(isJobAdExpand && styles.jobAdContainer.expand) }}>
                                <div style={styles.expandButton} onClick={handleJobAdButtonClick}>
                                    {Texts.jobAd}<span style={styles.expandButton.iconWrapper}><DropDownIcon style={{ ...styles.expandButton.icon, ...(isJobAdExpand && styles.expandButton.expandIcon) }} /></span>
                                </div>
                                {isJobAdExpand && <img src={jobView?.jobImageUri} style={styles.jobAd} alt={jobView.jobTitle} />}
                            </div>}
                            {(!jobView?.jobItem.archived && applyEnable) && <JobApply styles={styles.apply} jobId={jobView!.jobId} isSaved={isSaved} isGovernmentJob={jobView?.jobItem.isGovernmentJob!} isGovernmentJobApplication={jobView?.jobItem.isGovernmentJobApplication!} handleApplyButtonClick={handleApplyButtonClick} saveJob={saveJob} />}
                            <br />
                            <div style={styles.share.wrapper}>
                                <div style={styles.share.text}>{Texts.unemployment}</div>
                                <Share styles={styles.share} jobId={jobView!.jobId} jobTitle={jobView!.jobTitle} organizationName={jobView!.organization.organizationName}/>
                            </div>
                            {jobVideoUrl && <iframe style={styles.video} src={jobVideoUrl} frame-border="0" data-allow="autoplay; encrypted-media;" allowFullScreen></iframe>}
                        </div>
                        <div style={styles.rightWrapper}>
                            {/* This is loading only desktop platform */}
                            <div style={styles.desktopDetailCard}>
                                <VerticalJobDetailCard job={jobView!.jobItem} isSaved={isSaved} applyEnable={applyEnable} education={jobView!.education} experience={jobView!.experience} salaryRange={jobView!.salaryRange} handleApplyButtonClick={handleApplyButtonClick} saveJob={saveJob} />
                            </div>
                            <JobSectors styles={styles.jobSectors} sectors={jobView!.sectors} />
                            <JobOrganizationCard styles={styles.organizationCard} organization={jobView!.organization} buttonText={"ADD TO FAVOURITE"} />
                            {!isBannerOffed && <div style={styles.verticalBanner}>
                                <VerticalBanner adsenseClient={Texts.adClient} adsenseSlot={Texts.verticalAdSlot}/>
                            </div>}
                        </div>
                    </div>
                </div>}
            <ShortJobCardList title={'Related Jobs'} sortEnabled={false} loadJobs={getRelatedJobList}/>
            {!isBannerOffed && <div style={styles.horizontalBanner}>
                <HorizontalBanner adsenseClient={Texts.adClient} adsenseSlotOne={Texts.horizontalAdSlotOne} adsenseSlotTwo={Texts.horizontalAdSlotTwo}/>
            </div>}
            <Footer/>
        </>
    );
}