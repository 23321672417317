import React, { useEffect, useState } from 'react';
import { Job } from '../../models/Job';
import { Logo } from './Logo';
import { Tag } from './Tag';
import { ReactComponent as Disable } from '../../assets/svg/disable.svg';
import { ReactComponent as CVLess } from '../../assets/svg/cv_less.svg';
import { ReactComponent as Clock } from '../../assets/svg/clock.svg';
import { ReactComponent as Bookmark } from '../../assets/svg/bookmark.svg';
import { ReactComponent as BookmarkActive } from '../../assets/svg/bookmark-active.svg';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Briefcase } from '../../assets/svg/briefcase.svg';
import { ReactComponent as Globe } from '../../assets/svg/globe.svg';
import { ReactComponent as BumpUp } from '../../assets/svg/bump-up.svg';
import { useNavigate } from 'react-router-dom';
import { Button } from './Buttons/Button';
import { getSEOKeyword } from '../../helpers/Common';
import useSaveJob from '../../hooks/useSaveJob';

interface Props {
    styles: any;
    job: Job;
    saveJob?(jobId: number, isSave: boolean): void;
};

export const ShortJobCard: React.FC<Props> = (props) => {
    const navigate = useNavigate();    
    const {isSavedJob} = useSaveJob();
    const [hover, setHover] = useState<boolean>(false);
    const job = props.job;
    const styles = props.styles;
    const [isSaved, setIsSaved] = useState(isSavedJob(job.jobId));

    useEffect(() => {        
        setIsSaved(isSavedJob(job.jobId));
    }, [props.job])
    
    const saveJob = (event: any) => {
        event.stopPropagation();
        props.saveJob!(job.jobId, isSaved);
        setIsSaved(!isSaved);
    }

    const onClickHandler = () => {
        let seoKeyword = getSEOKeyword(job.jobTitle + "-" + job.organizationName);
        navigate("/jobs/view/" + job.jobId + "/" + seoKeyword);
    }

    const handleVideoTagClick = (event: any) => {
        event.stopPropagation();
        window.open(job.videoUrl, "_blank");
    }

    const handleDisabilityClick = (event: any) => {
        event.stopPropagation();
        navigate("/home/differentlyabledfriendlyemployment");
    }

    const handleCVLessClick = (event: any) => {
        event.stopPropagation()
        navigate("/jobs/cvless");
    }

    return (
        <div 
            onMouseEnter={() => { setHover(true); }}
            onMouseLeave={() => { setHover(false); }} 
            style={{...styles,...(hover ? styles.hover : styles.leave)}}
            onClick={onClickHandler}
        >            
            <div style={{...styles.wrapper, ...styles.unalignedWrapper}}>
                <Logo style={styles.logo} organizationId={job.organizationId} organizationName={job.organizationName} hasLogo={job.hasLogo} logoUri={job.logoUri} jobId={job.jobId} isGovernmentJob={job.isGovernmentJob} />  
                <div style={styles.countDown}>
                    <Clock style={styles.smallIcon}/>
                    {job.expireDayCountDown} {job.expireDayCountDown > 1 ? " days left" : " day left"}
                </div>              
                <div style={styles.tagWrapper}>
                    {job.urgent && <Tag styles={styles.urgentTag}>Urgent</Tag>}
                    {job.isVideoEnabled && <div onClick={handleVideoTagClick} style={styles.videoTag}>Video</div>}
                </div>
            </div>
            <div style={{...styles.wrapper, ...styles.titleWrapper}}>
                <span style={styles.title}>{job.jobTitle}</span>
                {job.bumpUp && <div style={styles.bumpUp}><BumpUp style={styles.bumpUpIcon} /></div>}
                <Button onClick={saveJob} style={styles.saveJobWrapper}>
                    {isSaved ? <>
                        <BookmarkActive style={{...styles.mediumIcon, ...styles.mediumIcon.jobSaved}} />
                        <span style={styles.jobSaved}>Saved</span>
                    </> : 
                    <Bookmark style={styles.mediumIcon} />}
                </Button>
            </div> 
            <div style={{...styles.wrapper, ...styles.organizationWrapper}}>
                <span style={styles.organization}>{job.organizationName}</span>
                {job.disabilityFriendly && <Disable style={styles.disableIcon} onClick={handleDisabilityClick} />}
                {job.isPreApplicationFormEnabled && <CVLess style={styles.cvlessIcon} onClick={handleCVLessClick} />}
            </div> 
            <div style={{...styles.wrapper, ...styles.locationWrapper}}>
                <Location style={styles.smallIcon}/>
                <span style={{...styles.text, ...styles.location}}>{job.locations.trim()}</span>
            </div>   
            <div style={{...styles.wrapper, ...styles.jobTypeWrapper}}>
                <Briefcase style={styles.smallIcon}/>
                <span style={{...styles.text, ...styles.jobType}}>{job.jobType}</span>
                {job.remote && <>
                    <Globe style={styles.smallIcon}/>
                    <span style={styles.text}>{"Remote"}</span>
                </>}
            </div>
        </div>        
    );
}