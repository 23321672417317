import { SearchPageTexts as Texts } from '../../helpers/LayoutTexts';
import { Button } from '../Shared/Buttons/Button';
import { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as DropDownIcon } from '../../assets/svg/drop_down.svg';
import { getSearchFilterOptions } from '../../services/JobService';
import { Sector } from '../../models/Sector';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { JobType } from '../../models/JobType';
import { Switch } from '../Shared/Buttons/Switch';
import { TagLoader } from '../Loaders/TagLoader';
import { Option } from '../../models/SelectInputs';
import useErrorPage from '../../hooks/useErrorPage';
import { SearchFilterOptions } from '../../models/SearchFilterOptions';
import { CareerLevel } from '../../models/CareerLevel';
import { ReactComponent as Notification } from '../../assets/svg/notification.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSEOKeyword } from '../../helpers/Common';
import { SectorSelect } from './SectorSelect';

interface Props {
    keyword?: string;    
    sectorIds: string;
    jobTypeIds: string;
    careerLevelIds: string;
    postedIn?: number;
    remoteEnabled: boolean;
    byCVLess: boolean;
    byWalkIn: boolean;    
    saveSearchHistory(searchName?: string, postedInName?: string): Promise<any>;
}

export const FilterPanel: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("filterPanel")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const [isFiltersExpand, setIsFiltersExpand] = useState<boolean>(isMobile ? false : true);
    const [isSectorExpand, setIsSectorExpand] = useState<boolean>(isMobile || props.sectorIds !== "");
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [isJobTypeExpand, setIsJobTypeExpand] = useState<boolean>(isMobile || props.jobTypeIds !== "");
    const [jobTypes, setJobTypes] = useState<JobType[]>([]);
    const [isCareerLevelExpand, setIsCareerLevelExpand] = useState<boolean>(isMobile || props.careerLevelIds !== "");
    const [careerLevels, setCareerLevels] = useState<CareerLevel[]>([]);
    const [postedOptions, setPostedOptions] = useState<Option[]>([]);
    const [isPostedExpand, setIsPostedExpand] = useState<boolean>(isMobile || props.postedIn !== undefined);    
    const [searchName, setSearchName] = useState<string>("");  
    const [toErrorPage] = useErrorPage();
    const [isSectorsSelected, setIsSectorsSelected] = useState<boolean>(false);
    const [sectorsHovered, setSectorsHovered] = useState<boolean>(false);
    const isMountedRef = useRef<boolean>(true);
    const retryCountRef = useRef<number>(1);
    const maxRetries = 3;
    let postedOptionList: Option[] = [
        { "value": 0, "option": "Any Time", "checked": false },
        { "value": 1, "option": "Last 24 hours", "checked": false },
        { "value": 7, "option": "Last Week", "checked": false },
        { "value": 14, "option": "Last 2 Week", "checked": false },
        { "value": 30, "option": "Last Month", "checked": false }
    ]

    useEffect(() => {                 
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {                     
        setStyles(styleContext.getComponentStyle("filterPanel"));                          
    }, [isMobile]);

    useEffect(() => {
        getFilterOptions();
    }, [props.byCVLess, props.byWalkIn]);

    async function getFilterOptions() {
        const selectedSectorIds = props.sectorIds !== "" ? props.sectorIds.split(",").map(Number) : [];
        const selectedJobTypeIds = props.jobTypeIds !== "" ? props.jobTypeIds.split(",").map(Number) : [];
        const selectedCareerLevelIds = props.careerLevelIds !== "" ? props.careerLevelIds.split(",").map(Number) : [];
        await getSearchFilterOptions(props.byCVLess, props.byWalkIn)
        .then((filterOptions: SearchFilterOptions) => {
            filterOptions.sectors.forEach((sector) => {
                let isSelected: boolean = selectedSectorIds.includes(sector.sectorId);
                sector.isSelected = isSelected;
            });
            filterOptions.jobTypes.forEach((jobType) => {
                let isSelected: boolean = selectedJobTypeIds.includes(jobType.jobTypeId);
                jobType.isSelected = isSelected;
            });
            filterOptions.careerLevels.forEach((careerLevel) => {
                let isSelected: boolean = selectedCareerLevelIds.includes(careerLevel.careerLevelId);
                careerLevel.isSelected = isSelected;
            });
            if(props.postedIn !== undefined) {
                postedOptionList.find(posted => posted.value === props.postedIn)!.checked = true;                
            }
            setSectors(filterOptions.sectors);
            setJobTypes(filterOptions.jobTypes); 
            setCareerLevels(filterOptions.careerLevels);
            setPostedOptions(postedOptionList);
            if(selectedSectorIds.length > 0) {
                setIsSectorsSelected(true);
            }            
        })
        .catch((error) => {
            if(isMountedRef.current) {         
                if (retryCountRef.current < maxRetries) {
                    // Retry the API call after 1 second
                    retryCountRef.current = retryCountRef.current + 1;
                    setTimeout(getFilterOptions, 1000);
                } 
                else {
                    // Redirect to error page after max retries
                    toErrorPage(error);
                }  
           }
        });
    } 

    const selectSector = (sectorId: number) => {
        let tempSectors = [...sectors];
        let selectedSector = tempSectors.find(x => x.sectorId === sectorId);
        selectedSector!.isSelected = !selectedSector!.isSelected;
        setSectors(tempSectors);
        if(!isSectorsSelected) {
            setIsSectorsSelected(true);
        }
        let selectedIds: string = "";
        tempSectors.forEach((sector) => {
            if(sector.isSelected){
                selectedIds = selectedIds + sector.sectorId + ",";
            }
        });
        selectedIds = selectedIds.substring(0, selectedIds.length - 1);
        if(selectedIds === "") {
            searchParams.delete("Sectors");
            setIsSectorsSelected(false);
        }
        else{
            searchParams.set("Sectors", selectedIds);
        }
        searchParams.set("page", "1");
        setPageSEO();        
        const newUrl = `?${searchParams}`;
        window.scrollTo(0, 0);
        navigate(newUrl);
    }

    const selectJobType = (index: number) => {
        let tempJobTypes = [...jobTypes];        
        tempJobTypes[index].isSelected = !tempJobTypes[index].isSelected;
        setJobTypes(tempJobTypes);
        let selectedIds: string = "";
        jobTypes.forEach((jobType) => {
            if(jobType.isSelected) {
                selectedIds = selectedIds + jobType.jobTypeId + ",";
            }
        });
        selectedIds = selectedIds.substring(0, selectedIds.length - 1);
        if(selectedIds === "") {
            searchParams.delete("JobTypeId");
        }
        else{
            searchParams.set("JobTypeId", selectedIds);
        }
        searchParams.set("page", "1");
        setPageSEO();        
        const newUrl = `?${searchParams}`;
        navigate(newUrl);
    }

    const selectCareerLevel = (index: number) => {
        let tempCareerLevels = [...careerLevels];        
        tempCareerLevels[index].isSelected = !tempCareerLevels[index].isSelected;
        setCareerLevels(tempCareerLevels);
        let selectedIds: string = "";
        careerLevels.forEach((careerLevel) => {
            if(careerLevel.isSelected) {
                selectedIds = selectedIds + careerLevel.careerLevelId + ",";
            }
        });
        selectedIds = selectedIds.substring(0, selectedIds.length - 1);
        if(selectedIds === "") {
            searchParams.delete("careerLevels");
        }
        else {
            searchParams.set("careerLevels", selectedIds);
        }
        searchParams.set("page", "1");
        const newUrl = `?${searchParams}`;
        navigate(newUrl);
    }

    const selectPostedOption = (index: number) => {        
        let checked = postedOptions[index].checked;                          
        let tempPostedOptions = [...postedOptions];
        tempPostedOptions.forEach((e) => {e.checked = false});                
        if(!checked) {            
            tempPostedOptions[index].checked = true;            
            let postedIn = postedOptionList[index].value;            
            searchParams.set("postedIn", postedIn.toString());  
        }
        else {            
            searchParams.delete("postedIn");
        }
        setPostedOptions(tempPostedOptions); 
        searchParams.set("page", "1");             
        const newUrl = `?${searchParams}`;
        navigate(newUrl);  
    }

    const setRemoteOption = () => {        
        let remoteEnable = !props.remoteEnabled;
        searchParams.set("remote", remoteEnable.toString());
        searchParams.set("page", "1");      
        const newUrl = `?${searchParams}`;            
        navigate(newUrl);
    }

    const handleSaveSearchHistory = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();        
        let postedOptionName: string | undefined;
        let postedOption = postedOptions.find(x => x.checked);
        if(postedOption) {
            postedOptionName = postedOption.option;
        }
        props.saveSearchHistory(searchName, postedOptionName);
    }

    const setPageSEO = () => {
        let sectorNames: string = "";
        let jobTypeNames: string = "";
        sectors.forEach((sector) => {
            if(sector.isSelected) {
                sectorNames = sectorNames + sector.friendlyName + "-";
            }
        });
        jobTypes.forEach((jobType) => {
            if(jobType.isSelected) {
                jobTypeNames = jobTypeNames + jobType.jobTypeName + "-";
            }
        });
        sectorNames = sectorNames.substring(0, sectorNames.length - 1);
        jobTypeNames = jobTypeNames.substring(0, jobTypeNames.length - 1);
        let seoKeyword = props.keyword + " " + sectorNames + " jobs " + jobTypeNames;
        seoKeyword = seoKeyword.trim();
        if(seoKeyword !== "jobs") {
            seoKeyword = getSEOKeyword(seoKeyword);
            searchParams.set("SeoKeyword", seoKeyword);
        }
        else {
            searchParams.delete("SeoKeyword");
        }
    }

    return (    
        <div style={{...styles.content, ...(isFiltersExpand && styles.content.expand)}}>            
            <div style={{...styles.filtersExpandButton, ...(isFiltersExpand && styles.filtersExpandButton.expand)}} onClick={() => setIsFiltersExpand((prev) => !prev)}>
                {Texts.searchFilters}<DropDownIcon style={{...styles.expandButtonIcon, ...(isFiltersExpand && styles.expandButtonIcon.up)}} />
            </div>
            {isFiltersExpand && <>
                <div onMouseEnter={() => setSectorsHovered(true)} onMouseLeave={() => setSectorsHovered(false)} onClick={() => setSectorsHovered(!sectorsHovered)}>                                                                                    
                    <div style={styles.expandButton} onClick={() => setIsSectorExpand((prev) => !prev)}>
                        {Texts.industry}<DropDownIcon style={{...styles.expandButtonIcon, ...(isSectorExpand && styles.expandButtonIcon.up)}} />
                    </div>            
                    {isSectorExpand && <>
                        <div style={styles.tagWrapper} className={"disableScrollBar"}>
                            {sectors.length > 0 ?
                            <>{[...(isSectorsSelected ? sectors.filter(x => x.isSelected) : sectors)].map((sector, index) => <div key={index} onClick={() => selectSector(sector.sectorId)} style={{ ...styles.tag, ...(sector.isSelected && styles.tag.selected) }}>
                                {sector.sectorName}{" (" + sector.sectorCount + ")"}
                            </div>
                            )}</> :
                            <TagLoader styles={styles.tagLoader} />}
                        </div>
                        {isSectorsSelected && sectorsHovered && <SectorSelect styles={styles.sectorSelect} sectors={sectors} selectOption={(sectorId: number) => selectSector(sectorId) } />}
                    </>}                        
                </div>
                <>                                                                                
                    <div style={styles.expandButton} onClick={() => setIsJobTypeExpand((prev) => !prev)}>
                        {Texts.jobType}<DropDownIcon style={{...styles.expandButtonIcon, ...(isJobTypeExpand && styles.expandButtonIcon.up)}} />
                    </div>        
                    {isJobTypeExpand && <div style={styles.tagWrapper} className={"disableScrollBar"}>
                        {jobTypes.length > 0 ? 
                        <>{jobTypes.map((jobType, index) =>
                            <div key={index} onClick={() => selectJobType(index)} style={{...styles.tag, ...(jobType.isSelected && styles.tag.selected)}}>
                                {jobType.jobTypeName}
                            </div>
                        )}</> : 
                        <TagLoader styles={styles.tagLoader} />} 
                    </div>}                                  
                </>
                <>                                                                                
                    <div style={styles.expandButton} onClick={() => setIsCareerLevelExpand((prev) => !prev)}>
                        {Texts.jobLevel}<DropDownIcon style={{...styles.expandButtonIcon, ...(isCareerLevelExpand && styles.expandButtonIcon.up)}} />
                    </div>
                    {isCareerLevelExpand && <div style={styles.tagWrapper} className={"disableScrollBar"}>
                        {careerLevels.length > 0 ? 
                        <>{careerLevels.map((careerLevel, index) =>
                            <div key={index} onClick={() => selectCareerLevel(index)} style={{...styles.tag, ...(careerLevel.isSelected && styles.tag.selected)}}>
                                {careerLevel.careerLevelName}
                            </div>
                        )}</> : 
                        <TagLoader styles={styles.tagLoader} />} 
                    </div>}                                  
                </>
                <>                                                                                
                    <div style={styles.expandButton} onClick={() => setIsPostedExpand((prev) => !prev)}>
                        {Texts.posted}<DropDownIcon style={{...styles.expandButtonIcon, ...(isPostedExpand && styles.expandButtonIcon.up)}} />
                    </div>        
                    {isPostedExpand && <div style={styles.tagWrapper} className={"disableScrollBar"}>
                        {postedOptions.map((postedOption, index) =>
                            <div key={index} onClick={() => selectPostedOption(index)} style={{...styles.tag, ...(postedOption.checked && styles.tag.selected)}}>
                                {postedOption.option}
                            </div>
                        )}
                    </div>}                                  
                </>
                <>                                                                                
                    <div style={styles.expandButton} onClick={setRemoteOption}>
                        {Texts.remote}
                        <Switch checked={props.remoteEnabled}/>
                        <span style={styles.slider}></span>
                    </div>                            
                </>
                <div style={styles.saveArea}>
                    {Texts.saveSearch}
                    <form style={styles.saveAreaWrapper} onSubmit={handleSaveSearchHistory}>
                        <input style={styles.saveAreaInput} placeholder={Texts.savePlaceholder} value={searchName} onChange={event => setSearchName(event.currentTarget.value)} />
                        {/* <Button type="button" style={styles.notifyButton}><Notification style={styles.notifyIcon}/></Button> */}
                        <Button type="submit" style={styles.saveButton}>{Texts.saveButton}</Button>
                    </form>
                </div>
            </>}            
        </div>
    );
}
