import React, { useContext, useEffect, useState } from 'react';
import { ShortJobCardList } from '../components/Shared/ShortJobCardList';
import { Footer } from '../components/Shared/Footer';
import { Header } from '../components/Shared/Header';
import { getCandidateRecommendedJobs, getCandidateAppliedJobs } from '../services/JobService';
import { Statistics } from '../components/Shared/Statistics';
import { JobCategories } from '../components/Home/JobCategories';
import { TopHiringOrganizations } from '../components/Home/TopHiringOrganizations';
import { LogoGride } from '../components/Shared/LogoGride';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { StyleContext } from '../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { Button } from '../components/Shared/Buttons/Button';
import { CommonTexts, CandidateHomeTexts as Texts, HomeTexts as HeaderTexts } from '../helpers/LayoutTexts';
import { VerticalBanner } from '../components/Shared/VerticalBanner';
import { SelectInputs } from '../models/SelectInputs';
import { AuthContext } from '../providers/AuthContextProvider';
import { CandidateSavedJobs } from '../components/Shared/CandidateSavedJobs';
import { getFeaturedOrganizations } from '../services/HomeService';
import { Helmet } from 'react-helmet-async';
import { LoggedCandidate } from '../models/LoggedCandidate';
import { ReactComponent as CloseCircle } from '../assets/svg/close-circle.svg';

const sortBySelectInputs: SelectInputs = {
    default: 'Default',
    options: [
        { "value": "ExpiryDateOnWebsite", "option": "Default"},
        { "value": "ExpiryDateOnWebsite DESC", "option": "Recent"},
        // { "value": "ViewCount DESC", "option": "Trending"} // This feature will be implemented in future release
    ]
}

export const CandidateHomePage: React.FC = () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("candidateHomePage"));    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [hasAppliedJobs, setHasAppliedJobs] = useState(true);    
    const [hasSavedJobs, setHasSavedJobs] = useState(true);
    const [openVerifyDialog, setOpenVerifyDialog] = useState<boolean>(true);
    const recommendedJobCount: number = isMobile ? 10 : 24;     
    const appliedJobCount: number = isMobile ? 6: 4;     
    const featuredOrganizationCount: number = isMobile ? 15 : 32;    

    useEffect(() => {                    
        if(candidate === null) {  
            const params = { callbackUrl: "/candidate/home" };
            navigate({
                pathname: '/candidates/login',
                search: `?${createSearchParams(params)}`
            });
        } 
    }, []);

    useEffect(() => {            
        setStyles(styleContext.getComponentStyle("candidateHomePage"));
    }, [isMobile]);

    const toMyApplications = () => {
        navigate('/candidate/myapplications');
    }

    const handleOpenVerifyDialog = () => {
        setOpenVerifyDialog(prev => !prev);
    }

    async function getRecommendedJobs(sortBy: string) {
        const preferredSectorIds = candidate!.preferredSectorIds.toString();
        return getCandidateRecommendedJobs(candidate!.candidateId, preferredSectorIds, recommendedJobCount, sortBy, candidate!.token);
    }

    async function getAppliedJobs() {
        return getCandidateAppliedJobs(candidate!.candidateId, appliedJobCount, candidate!.token);
    }
    
    return (
        <div>
            <Helmet><title>{CommonTexts.siteTitle}</title></Helmet>
            <Header styleSheet="fullHeader" searchEnable={true} searchSelectEnable={true} heading={HeaderTexts.heading} text={isMobile ? HeaderTexts.mobileText : HeaderTexts.desktopText} searchText={isMobile ? HeaderTexts.mobileSearch : HeaderTexts.desktopSearch}/>            
            {candidate && <ShortJobCardList styles={styles.recommendedJobs} title={Texts.recommendedJobs} sortEnabled={true} initialSortBy={"ExpiryDateOnWebsite"} sortSelectInputs={sortBySelectInputs} loadJobs={getRecommendedJobs}/>}
            <div style={styles.flippingWrapper}>
                <JobCategories styles={styles.jobCategories} />
                {candidate && <div>                    
                    <ShortJobCardList styles={styles.appliedJobs} title={Texts.appliedJobs} sortEnabled={false} loadJobs={getAppliedJobs} noJobsMessage={Texts.noAppliedJobs} setHasJobs={setHasAppliedJobs} onRedirect={toMyApplications}/>
                    {hasAppliedJobs && <Button style={styles.appliedJobsSeeAll} onClick={toMyApplications}>{Texts.viewAllUpper}</Button>}
                </div>}
            </div>
            <div style={styles.baseWrapper}>
                <div style={{...styles.leftWrapper, ...(!hasSavedJobs && styles.noSavedJobsWrapper)}}>
                    {candidate && <CandidateSavedJobs candidate={candidate} title={"Saved Job Posts"} viewAllEnable={true} setHasJobs={setHasSavedJobs} />}
                </div>
                {hasSavedJobs && <div style={styles.rightWrapper}>
                    <VerticalBanner adsenseClient={Texts.adClient} adsenseSlot={Texts.verticalAdSlot} />
                </div>}
            </div>
            <TopHiringOrganizations />
            {(!candidate?.accountVerified && openVerifyDialog) && <div style={styles.verifyDialog}>
                <div style={styles.verifyDialog.background} onClick={handleOpenVerifyDialog}></div>
                <div style={styles.verifyDialog.container}>
                    <CloseCircle style={styles.verifyDialog.close} onClick={handleOpenVerifyDialog}/>
                    <div style={styles.verifyDialog.text}>{Texts.verifyEmail}</div>
                </div>
            </div>} 
            <Statistics/>
            <LogoGride rowsCount={ isMobile ? 3 : 2 } loadOrganizations={getFeaturedOrganizations} recordsCount={featuredOrganizationCount} />
            <Footer/>
        </div>
    );
}