import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const topHiringOrganizations = {
    mobile: { 
        overflow: "hidden",
        marginTop: "14.49vw",             
        wrapper: { },
        title: {
            textAlign: "left" as "left",
            marginLeft: "4.83vw",
            fontFamily: Font.Default,
            fontWeight: "bold" as "bold",
            fontSize: "6.03vw",                    
            letterSpacing: "0px",
            color: Color.LightBlack,
            marginTop: "auto",
            marginBottom: "auto",
            opacity: "1"
        },
        sliderButtonWrapper: {
            display: "none",
            flexDirection: "row" as "row", 
            marginRight: "7.29vw",
            marginLeft: "auto",
            alignItems: "center"
        },
        rightSliderButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "3.6vw",
            height: "3.6vw",
            borderRadius: "50%",
            borderWidth: "0.5vw",
            backgroundColor: Color.Blue,
            borderColor: Color.LightBlue,
            border: "0.52vw solid " + Color.SharpBlue,
            marginLeft: "1.30vw",
            cursor: "pointer",
            fill: Color.White
        },
        leftSliderButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "2.6vw",
            height: "2.6vw",
            borderRadius: "50%",
            backgroundColor: Color.LightAsh,
            cursor: "pointer",
            fill: Color.SharpAsh                      
        },
        buttonIcon: {
            width: "42%",
            height: "auto"
        },      
        slider: {                        
            margin: "7.24vw -4.83vw 3.2vh 4.83vw",
            width: "152.17vw"
        },
        card: {
            wrapper: {                
                height: "19.32vw",
                display: "flex",
                flexDirection: "row" as "row",
                alignItems: "center",
                background: Color.LightAsh,
                borderRadius: "2.41vw",
                margin: "1.2vw 1.2vw",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                hover: {
                    background: Color.White,
                    boxShadow: "5px 5px 45px #00000029"
                }
            },
            logo: {
                width: "12.07vw",
                height: "12.07vw",
                marginLeft: "2.41vw",    
                background: "transparent",
                borderRadius: "1.2vw",
                objectFit: "contain"
            },
            contents: {
                width: "70%",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "5%",
                paddingRight: "5%",
                fontWeight: "bold",
                fontSize: "4.16vw",                    
                color: Color.Black,                
                hover: {
                    width: "55%"
                }
            },
            companyName: {
                whiteSpace: "nowrap",
                maxWidth: "85%",
                overflow: "hidden",
                float: "left",
                fontSize: "3.86vw"
            },
            jobCount: {
                fontWeight: "bold",
                fontSize: "2.65vw",                                 
                color: Color.Black,
                marginTop: "1.21vw"
            },
            label: {              
                fontWeight: "normal"
            },
            icon: {                
                width: "3.61vw",
                height: "3.61vw",
                marginLeft: "2.77vw"
            },
            buttonWrapper: {
                display: "none",
                marginLeft: "auto",
                marginRight: "0.78vw"
            },
            button: {
                width: "3.6vw",
                height: "3.6vw",
                backgroundColor: Color.Green,
                border: "0.52vw solid " + Color.LightGreen,           
                borderRadius: "50%",
                cursor: "pointer",
                fill: Color.White,
            },
            buttonIcon: {
                width: "0.72vw",
                height: "100%"
            }         
        },
        dots: { 
            marginTop: "0%", 
            marginBottom: "0%", 
            marginLeft: "-48%" 
        }
    },
    desktop: {        
        marginTop: "5.14vw", 
        title: {
            fontSize: "1.56vw",
            marginLeft: "7.29vw"
        }, 
        wrapper: {            
            display: "flex",
            flexDirection: "row" as "row",            
        },  
        sliderButtonWrapper: {
            display: "flex"
        },  
        slider: {   
            margin: "2.02vh -6.8vw 3.2vh 6.8vw",
            width:"171.67vw"
        },
        card: {
            logo: {
                width: "3.90vw",
                height: "3.90vw",
                borderRadius: "0.52vw",
                marginLeft: "1.04vw"
            },
            wrapper: {                
                height: "5.98vw",
                borderRadius: "0.52vw",
                margin: "0.41vw auto",
                width: "calc(100% - 0.83vw)"
            },
            contents: {
                fontSize: "1.2vw",
                marginTop: "0.26vw",
                marginRight: "1.30vw",
                padding: "0 0 0 1.30vw",                                
                hover: {
                    width: "8.38vw"
                }
            },
            companyName: {
                fontSize: "1.04vw"
            },
            jobCount: {
                fontSize: "0.93vw",
                marginTop: "0.26vw"
            },
            label: {
                fontSize: "0.93vw"
            },
            icon: {
                width: "1vw",
                height: "0.8vw",
                marginLeft: "0.5vw"
            },
            buttonWrapper: {
                hover: {
                    display: "block"
                }
            }
        },
        dots: { 
            marginLeft: "-51.4%" 
        }        
    }
}