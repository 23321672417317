import { Job } from "../../models/Job";
import { Logo } from "./Logo";
import { Tag } from "./Tag";
import { JobViewTexts as Texts } from '../../helpers/LayoutTexts';
import { ReactComponent as Disable } from '../../assets/svg/disable.svg';
import { ReactComponent as CvLess } from '../../assets/svg/cv_less.svg';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Clock } from '../../assets/svg/clock.svg';
import { ReactComponent as Breifcase } from '../../assets/svg/briefcase.svg';
import { ReactComponent as Globe } from '../../assets/svg/globe.svg';
import { Button } from "./Buttons/Button";
import { ReactComponent as Notification } from '../../assets/svg/notification.svg';
import { ReactComponent as Bookmark } from '../../assets/svg/bookmark-blue.svg';
import { ReactComponent as BookmarkActive } from '../../assets/svg/bookmark-active.svg';
import { Link } from "react-router-dom";

interface Props {
    styles: any;
    job: Job;
    isSaved: boolean;
    applyEnable: boolean;
    handleApplyButton(): void;
    saveJob (jobId: number): void;
}

export const HorizontalJobDetailCard: React.FC<Props> = (props) => { 
    const job: Job = props.job;
    const styles = props.styles;
    let applyStyles = styles.apply;

    const handleSaveButtonClick = () => {        
        props.saveJob(job.jobId);        
    }
    const handleNotifyButtonClick = () => {
        
    }

    const handleVideoTagClick = (event: any) => {
        event.stopPropagation();
        window.open(job.videoUrl, "_blank");
    }

    return (        
        <div style={styles}>            
            <div>
                <Logo style={styles.logo} organizationId={job.organizationId} organizationName={job.organizationName} hasLogo={job.hasLogo} logoUri={job.logoUri} jobId={job.jobId} isGovernmentJob={job.isGovernmentJob}/>                                                
            </div>
            <div style={{...styles.columnWrapper, ...styles.summaryWrapper}}>
                <div style={styles.rowWrapper}>                
                    {job.isVideoEnabled && <div onClick={handleVideoTagClick} style={styles.videoTag}>{Texts.video}</div>}                             
                    {job.urgent && <Tag styles={styles.urgentTag}>{Texts.urgent}</Tag>}
                </div>
                <div style={styles.rowWrapper}>
                    <span style={styles.title}>{job.jobTitle}</span>
                </div>
                <div style={styles.rowWrapper}>
                    <span style={styles.organization}>{job.organizationName}</span>
                    {job.disabilityFriendly &&  <Link to="/home/differentlyabledfriendlyemployment"><Disable style={styles.icon}/></Link> }
                    {job.isPreApplicationFormEnabled && <Link to="/jobs/cvless"><CvLess style={styles.icon}/></Link> }
                </div>
            </div>              
            <div style={{...styles.columnWrapper, ...styles.detailWrapper}}>
                <div style={styles.text}>
                    <Location style={styles.smallIcon} />
                    {job.locations}
                </div>
                <div style={styles.text}>
                    <Clock style={styles.smallIcon} />
                    {job.expireDayCountDown} {job.expireDayCountDown > 1 ? " days left" : " day left"}
                </div>
                <div style={styles.rowWrapper}>
                    <span style={styles.text}>
                        <Breifcase style={styles.smallIcon}/>{job.jobType}
                    </span>
                    {job.remote && <span style={{...styles.text, ...styles.remote}}>
                        <Globe style={styles.smallIcon}/>{"Remote"}                    
                    </span>}
                </div>
            </div>            
            <div style={styles.applyWrapper}>
                <div style={applyStyles.wrapper}>
                    {(!job.archived && props.applyEnable) && <>
                    <div style={applyStyles.rowWrapper}>
                        <Button type="button" style={applyStyles.saveButton} onClick={handleSaveButtonClick}>
                            {props.isSaved ? <>SAVED <BookmarkActive style={applyStyles.saveIcon} /></> : <>SAVE JOB<Bookmark style={applyStyles.saveIcon} /></>}
                        </Button>
                    </div>
                    {!job.isGovernmentJob && <div style={applyStyles.rowWrapper}>
                        <Button type="button" style={applyStyles.applyButton} onClick={props.handleApplyButton}>{Texts.apply}</Button>
                    </div>}
                    {job.isGovernmentJob && job.isGovernmentJobApplication && <div style={applyStyles.rowWrapper}>
                        <Button type="button" style={applyStyles.applyButton} onClick={props.handleApplyButton}>{Texts.downloadApplication}</Button>
                    </div>}
                    {/* temporally hide notify buttons */}
                        {/*<div style={applyStyles.rowWrapper}>
                            <Button type="button" style={applyStyles.notifyButton} onClick={handleNotifyButtonClick}><Notification style={applyStyles.icon} /></Button>
                        </div>*/}
                    </>}
                </div>
            </div>                   
        </div>
    );
}