import { Color } from "../enums/Color";

export const filterPanel = {
    mobile: {   
        content: {
            marginLeft: "4.83vw",
            marginRight: "4.83vw",            
            paddingLeft: "2%",            
            backgroundColor: Color.LightAsh,
            borderRadius: "2.41vw",        
            position: "relative",
            expand: {
                paddingBottom: "3.62vw"
            }
        },
        filtersExpandButton: {
            display: "flex",
            flexDirection: "row" as "row",
            height: "10.86vw",
            width: "102%",            
            marginLeft: "-2%",            
            paddingLeft: "3.62vw",
            paddingRight: "3.62vw",
            lineHeight: "10.86vw",
            border: "0.24vw solid" + Color.Black,
            backgroundColor: Color.DarkAsh,
            borderRadius: "2.41vw",        
            cursor: "pointer",
            boxSizing: "border-box",
            WebkitTapHighlightColor: 'transparent',
            expand: {
                borderRadius: "2.41vw 2.41vw 0 0",
                marginBottom: "3.62vw",
                border: "none"
            }
        },
        expandButton: {
            display: "flex",
            flexDirection: "row" as "row",
            height: "10.86vw",
            width: "98%",            
            marginBottom: "2.99vh",                        
            paddingLeft: "3.62vw",
            paddingRight: "3.62vw",
            lineHeight: "10.86vw",
            border: "1px solid " + Color.LightSilver,
            backgroundColor: Color.White,
            borderRadius: "1.20vw",        
            cursor: "pointer",
            fontSize: "3.86vw",
            boxSizing: "border-box",
            WebkitTapHighlightColor: 'transparent'
        },
        expandButtonIcon: {
            height: "4.58vw",
            width: "4.1vw",
            margin: "auto",
            marginRight: "0",
            transition: "transform .1s ease-in-out",
            up: {
                transform: "rotate(180deg)"
            }
        },
        tagWrapper: {
            display: "flex",
            flexDirection: "row",
            overflow: "scroll",
            marginTop: "-1.49vh",
            marginBottom: "1.49vh"
        },
        tag: {
            width: "max-content",
            height: "8.45vw",
            paddingLeft: "4.83vw",
            paddingRight: "4.83vw",            
            marginRight: "1.20vw",
            marginBottom: "1.49vh",
            textAlign: "center" as "center",
            lineHeight: "8.45vw",
            borderRadius: "6.03vw",
            backgroundColor: Color.Ash,        
            fontSize: "3.62vw",
            color: Color.DeepAsh,
            overflow:"hidden",
            cursor: "pointer",
            flex: "0 0 auto",
            selected: {
                backgroundColor: Color.Blue,
                color: Color.LightBlue
            }
        },        
        tagLoader: {
            width: "100%",
            height: "3.74vh",
            curve: "4vw"
        },
        slider: {

        },
        saveArea: {
            height: "33.33vw",
            backgroundColor: Color.Blue,
            color: Color.LightBlue,        
            fontSize: "3.86vw",
            borderRadius: "2.41vw",
            padding: "4.83vw 2.89vw",
            marginRight: "2%",            
            boxShadow: "5px 5px 45px #00000029"
        },
        saveAreaWrapper: {
            position: "relative" as "relative"
        },
        saveAreaInput: {
            width: "100%",
            fontSize: "3.86vw",            
            border: "none",
            borderRadius: "1.2vw",
            padding:"3.14vw 3.6vw 3.14vw 3.6vw",
            boxSizing: "border-box",
            marginTop: "3.62vw",
            height: "10.86vw"
        },
        saveButton: {
            width: "100%",
            height: "12.07vw",
            backgroundColor: Color.Green,
            border: "none",
            borderRadius: "2.41vw",
            cursor: "pointer",
            color: Color.White,                
            fontWeight: "bold" as "bold",
            marginTop: " 2.41vw",
            boxShadow: "0.72vw 0.72vw 2.41vw #00000027"
        },
        notifyButton: {
            position: "absolute",
            right: "2.66vw",
            top:"2.66vh",
            fill: Color.Green,
            background: "transparent",
            width: "6vw"
        },
        notifyIcon: {
            width: "100%"
        },
        sectorSelect: {           
            wrapper: {
                display: "block",
                position: "absolute",
                top: "41.4vw",
                left: "2.41vw",
                width: "calc(100% - 9.66vw)",
                backgroundColor: Color.White,
                height: "56.52vw",
                WebkitOverflowScrolling: "touch",
                padding: "0 2.41vw 1.35vh",
                borderRadius: "1.35vh",
                border: "1px solid " + Color.LightSilver,
                zIndex: 1
            },
            search: {
                display: "flex",
                height: "7.25vw",
                width: "calc(100% - 8.45vw)",
                margin: "2.41vw 2.41vw 0 2.41vw",
                fontSize: "3.38vw",
                paddingLeft: "1.35vh",
                color: Color.Black,
                border: "none",
                borderRadius: "3.62vw",
                backgroundColor: Color.LightAsh,
                icon: {
                    width: "2.41vw",
                    height: "auto",
                    fill: Color.SharpAsh,
                    position: "absolute" as "absolute",
                    top: "5.2vw",
                    left: "21vw",
                    cursor: "text"
                }
            },
            scrollArea: {
                height: "82%",
                overflow: "auto",
                marginTop: "1.38vw"
            },
            option: {
                margin: "0 1.38vw",
                padding: "1.44vw 0.46vw",
                cursor: "pointer",
                position: "relative" as "relative",
                display: "flex",
                zIndex: 1,
                overflow: "hidden",
                hover: {
                    backgroundColor: Color.LightBlue,
                    margin: "0 1.38vw",
                    borderRadius: "1.69vw",
                },
                leave: {
                    backgroundColor: "unset",
                },
            },
            checkbox: {
                borderRadius: "0.5vw",
                marginTop: "0.8vw",
                zIndex: 0,
                cursor: "pointer",
                border: "0.07vw solid " + Color.SharpAsh,
                height: "2.65vw",
                width: "2.65vw",
                float: "left",
                marginLeft: "2.17vw"
            },
            checkMark: {
                marginLeft: "2.25vw",
                marginTop: "0.8vw",
                height: "2.72vw",
                width: "2.72vw",
                float: "left"
            },
            optionLabel: {
                cursor: "pointer",
                marginLeft: "3.62vw",
                fontSize: "3.86vw",
                fontWeight: "500",
                width: "70%",
                color: Color.MiddleBlack
            },
            bottomBorder: {
                border: 0,
                height: "1px",
                width: "92%",
                background: Color.MiddleAsh,
                margin: "0 auto",
                zIndex: "1"
            },
            hoveredBorder: {
                background: Color.White,
                borderRadius: "1px"
            }
        }
    },
    desktop: {
        content: {
            marginTop: "2.86vw",
            marginLeft: "0",
            marginRight: "0",
            paddingTop: "1.04vw",            
            paddingLeft: "0.78vw",
            paddingRight: "0.78vw",
            backgroundColor: Color.LightAsh,
            borderRadius: "0.73vw",
            expand: {
                paddingBottom: "1.04vw"
            }
        },
        filtersExpandButton: {
            display: "none"
        },
        expandButton: {
            width: "100%",
            fontSize: "0.93vw",
            fontWeight: 500,
            height: "2.34vw",
            marginBottom: "3.2vh",
            lineHeight: "2.34vw",
            borderRadius: "0.26vw",
            padding: "0 0.78vw"
        },
        expandButtonIcon: {
            width: "0.8vw",
            height: "0.8vw"
        },
        tagWrapper: {
            display: "block",
            flexDirection: "unset",
            marginTop: "-0.73vw",
            overflow: "hidden"
        },
        tag: {
            width: "fit-content",
            height: "1.82vw",
            marginBottom: "0.26vw",
            marginRight: "0.26vw",
            textAlign: "center" as "center",
            lineHeight: "1.82vw",
            borderRadius: "1.30vw",
            backgroundColor: Color.Ash,        
            fontSize: "0.78vw",
            color: Color.DeepAsh,
            overflow:"hidden",
            cursor: "pointer",
            display: "inline-block",
            paddingLeft: "1.04vw",
            paddingRight: "1.04vw",
            selected: {
                backgroundColor: Color.Blue,
                color: Color.LightBlue
            }
        },
        tagLoader: {            
            curve: "2vh"
        },
        saveArea: {
            height: "7.55vw",
            marginRight: "0",
            fontSize: "0.93vw",
            borderRadius: "0.52vw",
            padding: "1.30vw 0.62vw 1.56vw 0.78vw"
        },
        saveAreaInput: {
            fontSize: "0.83vw",
            height: "2.34vw",
            borderRadius: "0.36vw",
            padding: "1.01vh 2.66vw 1.01vh 0.78vw",
            marginTop: "1.85vh"
        },
        saveButton: {
            fontSize: "1.04vw",
            height: "2.6vw",
            marginTop: "0.52vw",
            borderRadius: "0.52vw",
            boxShadow: "0.15vw 0.15vw 0.52vw #00000027"
        },
        notifyButton: {
            top: "3.2vh",
            right: "0.78vw",
            width: "1.72vw"
        },
        sectorSelect: {
            wrapper: {
                width: "14.63vw",
                height: "15.15vw",
                padding: "0.52vw",
                borderRadius: "0.52vw",
                top: "2.34vw",
                left: "10.72vw"
            },
            search: {
                width: "90%",
                fontSize: "0.72vw",
                height: "1.56vw",
                borderRadius: "0.78vw",
                margin: "0",
                paddingLeft: "0.78vw",
                icon: {
                    width: "0.78vw",
                    left: "4.32vw",
                    top: "1vw"
                }
            },
            scrollArea: {
                marginTop: "0.36vw"
            },
            option: {
                margin: "0",
                padding: "0.36vw",
                hover: {
                    margin: "0",
                    borderRadius: "0.364vw"
                }
            },
            optionLabel: {
                fontSize: "0.83vw",
                marginLeft: "0.51vw"
            },
            checkbox: {
                marginLeft: "0.46vw",
                borderRadius: "0.1vw",
                marginTop: "0.2vw",
                height: "0.57vw",
                width: "0.57vw"
            },
            checkMark: {
                marginLeft: "0.46vw",
                marginTop: "0.2vw",
                height: "0.7vw",
                width: "0.7vw"
            }
        }
    }
}