import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../providers/StyleContextProvider';
import { ReactComponent as LeftArrow } from '../../assets/svg/left_arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/svg/right_arrow.svg';
import { Button } from './Buttons/Button';
import { merge } from 'react-merge';

interface Props {
    page: number;
    totalPages: number;
    handlePagination: (page: number) => void;
    extendedStyles?: any;
}

export const Pagination : React.FC<Props> = ({page, totalPages, handlePagination, extendedStyles}) => {
    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("pagination"));    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    
    useEffect(() => {        
        // merge base styles with component specific styles
        let style = styleContext.getComponentStyle("pagination"); 
        let mergedStyles = merge(style, extendedStyles);
        setStyles(mergedStyles);                            
    }, [isMobile]);

    return (        
        <div style={styles}>
            <Button type="button" disabled={page === 1} style={{...styles.previousButton, ...((page !== 1) && styles.previousButton.lessMargin)}} onClick={() => handlePagination(page - 1)}><LeftArrow style={styles.arrowIcon}/></Button>
            <Button type="button" style={{...styles.button, ...((page === 1) && styles.button.active)}} onClick={() => handlePagination(1)}>{1}</Button>
            {isMobile ? <>
                {page > 3 && <div style={styles.dots}>...</div>}
                {page === totalPages && totalPages > 3 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page - 2)}>{page - 2}</Button>
                )}
                {page > 2 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page - 1)}>{page - 1}</Button>
                )}
                {page !== 1 && page !== totalPages && (
                    <Button type="button" style={{...styles.button, ...styles.button.active}} onClick={() => handlePagination(page)}>{page}</Button>
                )}
                {page < totalPages - 1 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page + 1)}>{page + 1}</Button>
                )}
                {page === 1 && totalPages > 3 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page + 2)}>{page + 2}</Button>
                )}
                {page < totalPages - 2 && <div style={styles.dots}>...</div>}
            </>
            : <>
                {page > 5 && <div style={styles.dots}>...</div>}
                {page === totalPages && totalPages > 5 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page - 4)}>{page - 4}</Button>
                )}
                {page > 4 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page - 3)}>{page - 3}</Button>
                )}
                {page > 3 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page - 2)}>{page - 2}</Button>
                )}
                {page > 2 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page - 1)}>{page - 1}</Button>
                )}
                {page !== 1 && page !== totalPages && (
                    <Button type="button" style={{...styles.button, ...styles.button.active}} onClick={() => handlePagination(page)}>{page}</Button>
                )}
                {page < totalPages - 1 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page + 1)}>{page + 1}</Button>
                )}
                {page < totalPages - 2 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page + 2)}>{page + 2}</Button>
                )}
                {page < totalPages - 3 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page + 3)}>{page + 3}</Button>
                )}
                {page === 1 && totalPages > 5 && (
                    <Button type="button" style={styles.button} onClick={() => handlePagination(page + 4)}>{page + 4}</Button>
                )}
                {page < totalPages - 3 && <div style={styles.dots}>...</div>}
            </>}
            {totalPages !== 1 && (    
                <Button type="button" style={{...styles.button, ...((page === totalPages) && styles.button.active)}} onClick={() => handlePagination(totalPages)}>{totalPages}</Button>
            )}
            <Button disabled={page === totalPages} type="button" style={{...styles.nextButton, ...((page !== totalPages) && styles.nextButton.lessMargin)}} onClick={() => handlePagination(page + 1)}><RightArrow style={styles.arrowIcon}/></Button>
        </div>        
    );
};
