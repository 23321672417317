import { Job } from "../../models/Job";
import { Logo } from "./Logo";
import { Tag } from "./Tag";
import { JobViewTexts as Texts } from '../../helpers/LayoutTexts';
import { ReactComponent as Disable } from '../../assets/svg/disable.svg';
import { ReactComponent as CvLess } from '../../assets/svg/cv_less.svg';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Clock } from '../../assets/svg/clock.svg';
import { ReactComponent as Breifcase } from '../../assets/svg/briefcase.svg';
import { ReactComponent as Globe } from '../../assets/svg/globe.svg';
import { useContext, useEffect, useState } from "react";
import { ReactComponent as DropDownIcon } from '../../assets/svg/drop_down.svg';
import { JobApply } from "../JobView/JobApply";
import { Share } from "./Share";
import { StyleContext } from "../../providers/StyleContextProvider";
import { useMediaQuery } from "react-responsive";
import { merge } from "react-merge";
import { ReactComponent as BookmarkActive } from '../../assets/svg/bookmark-active.svg';
import { Link } from "react-router-dom";

export interface Props {
    styles?: any,
    job: Job;
    isSaved: boolean;
    applyEnable: boolean;
    education: string;
    experience: string;
    salaryRange: string;
    handleApplyButtonClick?(): void;
    saveJob (jobId: number): void;
}

export const VerticalJobDetailCard: React.FC<Props> = (props) => {
    const job: Job = props.job;
    const [isSummaryExpand, setIsSummaryExpand] = useState<boolean>(true);
    const styleContext = useContext(StyleContext);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });        
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("verticalJobDetailCard"));

    useEffect(() => {
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("verticalJobDetailCard"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);   
    }, [isMobile]);
    
    const handleSummaryButtonClick = () => {
        setIsSummaryExpand((prev) => !prev);
    }

    const handleVideoTagClick = (event: any) => {
        event.stopPropagation();
        window.open(job.videoUrl, "_blank");
    }
     
    return (        
        <div style={styles}>            
            <div style={{...styles.wrapper, ...styles.unalignedWrapper}}>
                <Logo style={styles.logo} organizationId={job.organizationId} organizationName={job.organizationName} hasLogo={job.hasLogo} logoUri={job.logoUri} jobId={job.jobId} isGovernmentJob={job.isGovernmentJob} />
                <div style={styles.emptyTag}></div>                                   
                {job.isVideoEnabled && <div onClick={handleVideoTagClick} style={styles.videoTag}>{Texts.video}</div>}                             
                {job.urgent && <Tag styles={styles.urgentTag}>{Texts.urgent}</Tag>}                                
            </div>
            <div style={styles.wrapper}>
                <span style={styles.title}>{job.jobTitle}</span>
                {props.isSaved && <span style={styles.jobSaved}><BookmarkActive style={styles.jobSaved.icon}/>Saved</span>}
            </div> 
            <div style={styles.wrapper}>
                <span style={styles.organization}>{job.organizationName}</span>
                 {job.disabilityFriendly &&  <Link to="/home/differentlyabledfriendlyemployment"> <Disable style={styles.icon}/> </Link>}
                {job.isPreApplicationFormEnabled && <Link to="/jobs/cvless"> <CvLess style={styles.icon}/> </Link>}
            </div> 
            <div style={{...styles.wrapper, ...styles.wrapper.changeDirection}}>
                <div style={styles.text}>
                    <Location style={styles.smallIcon}/>
                    <span style={styles.textAlign}>{job.locations}</span> 
                </div>
                <div style={isMobile ?  {...styles.text, ...styles.text.textSpaced} : styles.text}>
                    <Clock style={styles.smallIcon}/>
                    {job.expireDayCountDown} {job.expireDayCountDown > 1 ? " days left" : " day left"}
                </div>
            </div>
            <div style={{...styles.wrapper, ...styles.wrapper.removePadding}}>
                <div style={styles.text}>
                    <Breifcase style={{...styles.smallIcon, ...styles.briefcaseIcon}}/>{job.jobType === "Full-Time" ? "Full Time" : job.jobType}
                </div>
                {job.remote && <div style={{...styles.text, ...styles.remote}}>
                    <Globe style={styles.smallIcon}/>{"Remote"}                    
                </div>}
            </div>    
            <hr style={styles.line}/>
            <div style={styles.flippingWrapper}>
                <div>
                    <div style={styles.summaryButtonWrapper}>
                        <div style={styles.summaryButton} onClick={handleSummaryButtonClick}>
                            {Texts.summary}<span style={styles.summaryButton.iconWrapper}><DropDownIcon style={{ ...styles.summaryButton.icon, ...(isSummaryExpand && styles.summaryButton.expandIcon) }} /></span>
                        </div>
                    </div>
                    {isSummaryExpand && <div style={styles.summary}>
                        <div style={styles.overview}>{job.overview}</div>
                        <div style={styles.summary.item}>
                            <div style={styles.summary.key}>{Texts.education}</div>
                            <div style={styles.summary.value}>{props.education}</div>
                        </div>
                        <div style={styles.summary.item}>
                            <div style={styles.summary.key}>{Texts.experience}</div>
                            <div style={styles.summary.value}>{props.experience}</div>
                        </div>
                        <div style={styles.summary.item}>
                            <div style={styles.summary.key}>{Texts.salary}</div>
                            <div style={{...styles.summary.value, ...styles.summary.lastValue}}>{props.salaryRange}</div>
                        </div>
                    </div>}
                    <hr style={{...styles.line, ...styles.lineTwo}} />
                    <div style={styles.wrapper}>
                        <Share styles={styles.share} jobId={job.jobId} jobTitle={job.jobTitle} organizationName={job.organizationName}/>
                    </div>
                </div>
                {(!job.archived && props.applyEnable) && <JobApply styles={styles.apply} jobId={job.jobId} isSaved={props.isSaved} isGovernmentJob={job.isGovernmentJob} isGovernmentJobApplication={job.isGovernmentJobApplication} handleApplyButtonClick={props.handleApplyButtonClick!} saveJob={props.saveJob} />}
            </div>            
        </div>
    );
}