import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FooterTexts as Texts } from '../../helpers/LayoutTexts';
import { StyleContext } from '../../providers/StyleContextProvider';
import { List } from './List';
import { XpressJobsTitle } from './XpressJobsTitle';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Phone } from '../../assets/svg/phone.svg';
import { ReactComponent as Mail } from '../../assets/svg/mail.svg';
import { ReactComponent as LinkedIn } from '../../assets/svg/linkedin.svg';
import { ReactComponent as Whatsapp } from '../../assets/svg/whatsapp.svg';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/svg/twitter.svg';
import { ReactComponent as Curve1 } from '../../assets/svg/footer-curve-1.svg';
import { ReactComponent as Curve2 } from '../../assets/svg/footer-curve-2.svg';
import { ReactComponent as FooterLeft } from '../../assets/svg/footer-left.svg';
import { ReactComponent as FooterRight } from '../../assets/svg/footer-right.svg';
import { Button } from './Buttons/Button';
import { getHost, validateEmail } from '../../helpers/Common';
import { useAlert } from '../../hooks/useAlert';
import { updateCandidateJobsByEmail } from '../../services/CandidateService';
import { ResponseModel } from '../../models/ResponseModel';
import useErrorPage from '../../hooks/useErrorPage';
import { AuthContext } from '../../providers/AuthContextProvider';
import { LoggedCandidate } from '../../models/LoggedCandidate';

export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const styleContext = useContext(StyleContext);
  const authContext = useContext(AuthContext);
  const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
  const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("footer"));
  const [email, setEmail] = useState<string>("");
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  const { Alert, openAlert } = useAlert();
  const [toErrorPage] = useErrorPage();
  const baseURL = getHost();

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("footer"));
  }, [isMobile]);

  const menuList: { label: string, items: any[] }[] = [
    {
      label: "Job Seeker",
      items: [
        { item: <div onClick={() => redirectByLogin()} style={styles.menu.menuItem.item}>{"Login"}</div> },
        { item: <div onClick={() => redirectJobs("")} style={styles.menu.menuItem.item}>{"Jobs"}</div> },
        { item: <div onClick={() => redirectJobs("/cvless")} style={styles.menu.menuItem.item}>{"CV-Less Jobs"}</div> },
        { item: <Link to={'/cvFormats'} style={styles.menu.menuItem.item}>{"CV Formats Store"}</Link> }
        //{ item: <Link to={'/smsRegister'} style={styles.menu.menuItem.item}>{"SMS Job Alert"}</Link> }
      ]
    },
    {
      label: "Recruiters",
      items: [
        { item: <div onClick={() => window.location.assign(baseURL + "recruiter/account")} style={styles.menu.menuItem.item}>{"Login"}</div> },
        { item: <div onClick={() => window.location.assign(baseURL + "for-recruiters#faq")} style={styles.menu.menuItem.item}>{"FAQ"}</div> },
        { item: <Link to={'/testimonials'} style={styles.menu.menuItem.item}>{"Testimonials"}</Link> },
        { item: <div onClick={() => window.location.assign(baseURL + "for-recruiters#content")} style={styles.menu.menuItem.item}>{"Features"}</div> }
      ]
    },
    {
      label: "Support",
      items: [
        { item: <Link to={'/contact'} style={styles.menu.menuItem.item}>{"Contact"}</Link> },
        { item: <Link to={'/home/preferredPartners'} style={styles.menu.menuItem.item}>{"Partners"}</Link> },
        { item: <Link to={'/home/differentlyabledfriendlyemployment'} style={styles.menu.menuItem.item}>{"Differently-Abled Employment"}</Link> },
        { item: <Link to={'/press'} style={styles.menu.menuItem.item}>{"Press Releases"}</Link> },
        { item: <Link to={'/termsconditions'} style={styles.menu.menuItem.item}>{"Terms and Conditions"}</Link> },
        { item: <Link to={'/privacyPolicy'} style={styles.menu.menuItem.item}>{"Privacy Policy"}</Link>}
      ]
    }
  ];

  const redirectJobs = (byCVLess: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (location.pathname.includes('/jobs')) {
      navigate({
        pathname: "/jobs" + byCVLess,
        search: `?${searchParams}`
      });
    }
    else {
      navigate("/jobs" + byCVLess);
    }
  }

  const redirectByLogin = () => {
    if(candidate !== null) {
      navigate("/candidate/home");
    }
    else{
      navigate("/candidates/login");
    }
  }

  async function submitSubscribe(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (validateEmail(email)) {
      await updateCandidateJobsByEmail(email)
        .then((response: ResponseModel) => {
          if (response.status) {
            openAlert("success", response.message);
          }
          else {
            openAlert("error", response.message);
          }
        })
        .catch((error) => {
          toErrorPage(error);
        });
    }
    else {
      openAlert("error", Texts.emailError);
    }
  }

  return (
    <div style={styles}>
      <Curve1 style={styles.curveSvg1} />
      <Curve2 style={styles.curveSvg2} />
      {/* This is rendering only in desktop */}
      <FooterLeft style={styles.leftSvg} />
      <FooterRight style={styles.rightSvg} />
      <XpressJobsTitle style={styles.title} />
      <div style={styles.details}>
        <Link to={"/contact"} style={styles.wrapper}>
          <Location style={{ ...styles.smallIcon, ...styles.locationIcon }} />
          <div>{Texts.address}</div>
        </Link>
        <div style={styles.subWrapper}>
         <a style={styles.wrapper} href={"mailto:" + Texts.email}>
          <Mail style={{ ...styles.smallIcon, ...styles.mailIcon }} />
           <div>{Texts.email}</div>
         </a>
         <a style={styles.wrapper} href={"tel: " + Texts.telephone}>
           <Phone style={{ ...styles.smallIcon, ...styles.phoneIcon }} />
           <div>{Texts.telephone}</div>
         </a>
        </div>
      </div>
      <div style={styles.newsletter}>
        <div style={styles.newsletter.heading}>{"Newsletters"}</div>
        <p style={styles.newsletter.text}>{Texts.newsletterText}</p>
      </div>
      <form style={styles.searchArea} onSubmit={submitSubscribe}>
        <input style={styles.searchArea.input} placeholder={Texts.searchPlaceholder} onChange={(e) => setEmail(e?.target.value!)} />
        <Button type="submit" style={styles.searchArea.button}>
          SUBSCRIBE
        </Button>
      </form>
      <div style={styles.menu}>
        {menuList.map((menuItem, index) =>
          <List key={index} styles={styles.menu.menuItem} label={menuItem.label} items={menuItem.items} />
        )}
      </div>
      <div style={styles.copyright}>
        <div style={styles.socialIconWrapper}>
          <a href="https://www.linkedin.com/company/xpressjobs" target='_blank'>
            <LinkedIn style={{...styles.largeIcon, ...styles.linkedIn}} />
          </a>
          <a href="https://wa.me/94707422414" target="_blank">
            <Whatsapp style={{...styles.largeIcon, ...styles.whatsapp}} />
          </a>
          <a href="https://www.facebook.com/XpressJobs/" target='_blank'>
            <Facebook style={{...styles.largeIcon, ...styles.facebook}} />
          </a>
          <a href="https://www.instagram.com/xpress.jobs/" target='_blank'>
            <Instagram style={{...styles.largeIcon, ...styles.instagram}} />
          </a>
          <a href="https://twitter.com/xpress_jobs" target='_blank'>
            <Twitter style={{...styles.largeIcon, ...styles.twitter}} />
          </a>
        </div>
        {Texts.copyright}
      </div>
      <Alert />
    </div>
  );
}