import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const testimonialsPage = {
    mobile: {        
        rowWrapper: {
            display: "block"
        },
        tabs: {
            container: {
                position: "relative" as "relative",
                marginTop: "-2.23vh",
                textAlign: "-moz-center",
                zIndex: "1"
            },
            linkWrapper: {                
                display: "flex",
                flexDirection: "row" as "row",
                height: "4.46vh",
                width: "fit-content",
                backgroundColor: Color.LightAsh,
                borderRadius: "1.20vw",
                margin: "auto",
                textAlign: "center"
            },
            link: {
                width: "37.19vw",
                height: "4.46vh",
                lineHeight: "4.46vh",
                flexShrink: "0",
                fontFamily: Font.Default,
                fontsize: "3.86vw",
                color: Color.DeepAsh,
                borderRadius: "1.20vw",
                alignSelf: "center" as "center",
                cursor: "pointer",
                active: {
                    backgroundColor: Color.DeepBlack,
                    color: Color.LightAsh
                }
            },
            contentWrapper: {
                margin: "4.91vh 5% 0 5%",
                textAlign: "-webkit-center" as "-webkit-center"
            }
        },
        slider: {
            overflow: "hidden",
            title:{
                fontSize: "3.86vw",
                fontWeight: "bold" as "bold",
                textAlign: "left" as "left",
                marginRight: "2.41vw",
                marginLeft: "4vw",
                color: Color.LightBlack
            },
            wrapper: {
                display: "flex",
                flexDirection: "row" as "row"
            },
            sliderButton: {
                position: "absolute",
                top: "28vh",
                display: "none",
                width: "2.60vw",
                height: "2.60vw",
                border: "none",
                borderRadius: "1.30vw",
                background: Color.LightAsh,
                zIndex: 1,
                fill: Color.SharpAsh,
                left: {
                    left: 0
                },
                right: {
                    right: 0
                }
            },
            card:{
                width: "99%",
                height: "120vw",
                borderRadius: "2.41vw",
                border: "none",
                backgroundColor: Color.AliceBlue,
                margin: "6.13vw 0.5% 38vw 0.5%",
                rowWrapper: {
                    display: "flex",
                    flexDirection: "row" as "row"
                },
                title: {
                    width: "62%",
                    margin: "3.57vh 8.21vw 0 4.83vw",
                    fontFamily: "Poppins",
                    fontSize: "4.83vw",
                    fontWeight: "bold" as "bold",
                    textAlign: "left" as "left",
                    maxHeight: "14vw",
                    overflowY: "auto",
                    hover: {
                        overflowY:"auto"
                    }
                },
                quoteIcon: {
                    width: "15.23vw",
                    height: "13.77vw",
                    float: "right" as "right",
                    margin: "-1.78vh 6.03vw 0 0"  
                },
                starWrapper: {
                    margin: "5.79vw 0 0 4.83vw",
                    textAlign: "left" as "left"
                },
                star: {
                    marginRight: "4.83vw"
                },
                description: {
                    margin: "5.80vw 4.83vw 20vw 4.83vw",
                    height: "50vw",
                    fontSize: "3.38vw",                    
                    textAlign: "left" as "left",
                    color: Color.MiddleBlack,
                    lineHeight: 1.8,
                    maxHeight:"62.36vw",
                    overflowY: "auto",
                    hover:{
                        overflowY:"auto"
                    }
                },
                userWrapper: {
                    width: "100%",
                    overFlow: "hidden",
                    position: "absolute" as "absolute",
                    textAlign: "left" as "left",
                    margin: "-3vw 0 40vw 6.52vw"
                },
                userLogo: {
                    width: "22.46vw",
                    height: "22.46vw",                    
                    borderRadius: "12.07vw",
                    border: "1.69vw solid " + Color.White,
                    objectFit: "cover"
                },
                userName: {
                    fontSize: "3.86vw",
                    fontWeight: "bold" as "bold",
                    color: Color.LightBlack,
                    marginTop: "2.41vw"
                },
                userJobTitle: {  
                    width: "83.816vw",
                    height: "auto",                  
                    fontSize: "3.38vw",                    
                    color: Color.SharpAsh,
                    marginTop: "1.44vw"
                },
                organization: {
                    fontSize: "3.38vw",
                    fontWeight: "bold" as "bold",
                    color: Color.SharpAsh,
                    marginTop: "1.44vw"
                },
                organizationLogoWrapper: {
                    marginLeft: "50vw",
                    marginTop: "-10vw"
                },
                organizationLogo:{
                    width: "31.64vw",
                    height: "15.94vw",                    
                    borderRadius: "3.62vw",
                    margin: "2.86vw 3.38vw 5.34vw auto",
                    objectFit: "contain"
                },
                bottomWrapper: {
                    display: "flex",
                    flexDirection: "row" as "row",
                    marginBottom: "16.74vh"
                }                
            },
            image_1: {
                width: "85%",
                margin: "29.42vw 5% 0 3%"
            },
            dots: {
                marginBottom: "-12.19vw",
                marginRight: "10vw"
            }
        },
        image_2: {
            display: "none",
            width: "22.89vw",
            height: "34.92vw",
            zIndex: 1
        },
        image_3: {
            display: "none",
            width: "22.89vw",
            height: "32.41vw",
            zIndex: 1
        },        
        reviewContainer: {
            overflow: "hidden" as "hidden"
        },
        topReviewGride: {
            display: "flex",
            flexDirection: "column" as "column",
            width: "90%",
            margin: "0 auto 20.04vw auto"
        },
        topReviewSlider: {
            display: "none"
        },
        sliderButtonWrapper: {
            display: "none",
            flexDirection: "row" as "row",
            margin: "auto 7% 0 auto"
        },
        rightSliderButton: {
            width: "3.6vw",
            height: "3.6vw",
            borderRadius: "50%",
            borderWidth: "0.5vw",
            backgroundColor: Color.Blue,
            borderColor: Color.LightBlue,
            border: "0.52vw solid " + Color.SharpBlue,
            marginLeft: "20px",
            cursor: "pointer",
            fill: Color.White
        },
        buttonIcon: {
            width: "0.83vw",
            height: "0.94vw",
            marginTop:"0.20vw "
        },
        leftSliderButton: {
            width: "3.6vw",
            height: "3.6vw",
            borderRadius: "50%",
            backgroundColor: Color.LightAsh,
            borderColor: Color.White,
            border: "0.3vw solid white",
            cursor: "pointer",
            fill: Color.SharpAsh                  
        },
        reviewTitle: {
            fontSize: "3.86vw",
            fontWeight: "bold" as "bold",
            textAlign: "left" as "left",
            margin: "8.03vh 5% 0.89vh 5%",
            color: Color.LightBlack
        },
        reviewCard:{            
            borderRadius: "4.83vw",
            border: "0.96vw solid " + Color.AliceBlue,            
            marginTop: "7.72vw",
            rowWrapper: {
                display: "flex",
                flexDirection: "row" as "row"
            },
            title: {
                width: "60%",
                height: "14.00vw",
                margin: "4.46vh 0 0 4.83vw",
                fontFamily: "Poppins",
                fontSize: "4.83vw",
                fontWeight: "bold" as "bold",
                textAlign: "left" as "left",
                overflowY: "auto",
                hover: {
                    overflowY: "auto"                    
                }
            },
            quoteIcon: {
                width: "25.84vw",
                height: "10.82vh",
                float: "right" as "right",
                margin: "2.23vh 4.83vw 0 0",
                fill: Color.AliceBlue    
            },
            starWrapper: {
                margin: "7.24vw 0 0 4.83vw",
                textAlign: "left" as "left"
            },
            star: {
                marginRight: "0.72vw"
            },
            description: {
                height: "13.50vh", 
                margin: "2.47vw 4.83vw 0 4.83vw",
                fontFamily: Font.Poppins,
                fontSize: "3.62vw",                    
                textAlign: "left" as "left",
                color: Color.MiddleBlack,
                lineHeight: 1.3, 
                overflowY: "auto",
                hover: {
                    overflowY: "auto"                    
                }
            },
            userWrapper: {                
                textAlign: "left" as "left",
                margin: "3.34vh 0 2.23vh 4.83vw"
            },
            userLogo: {
                width: "10.14vw",
                height: "10.14vw",                
                borderRadius: "6.03vw",
                objectFit: "cover"        
            },
            userName: {
                fontFamily: "Poppins",
                fontSize: "3.62vw",
                fontWeight: "bold" as "bold",
                color: Color.Black,
                marginTop: "1.11vh"
            },
            userJobTitle: {                
                fontFamily: "Poppins",
                fontSize: "3.38vw",                    
                color: Color.Black,
                marginTop: "0.22vh"
            },
            organization: {
                display: "none"
            },
            organizationLogoWrapper: {
                height: "17vw",
                margin: "5vw 2vw 0 17vw"    
            },
            organizationLogo:{
                width: "31.64vw",
                height: "15.94vw",                
                borderRadius: "3.62vw",
                objectFit: "contain",
                border:"2px solid" + Color.LightAsh
            },
            bottomWrapper: {
                display: "flex",
                flexDirection: "row" as "row"                
            }                
        }
    },
    desktop: {
        rowWrapper: {
            display: "flex",
            flexDirection: "row" as "row"
            },
        tabs: {
            container: {                
                width: "55%",
                margin: "-1.3vw 3.5vw 0 2.36vw"
            },
            linkWrapper: {
                width: "fit-content",
                height: "2.34vw",
                borderRadius: "0.52vw"
            },
            link: {
                width: "8.85vw",
                height: "2.34vw",
                lineHeight: "2.34vw",
                fontSize: "0.94vw",
                borderRadius: "0.52vw"
            },
            contentWrapper: {
                margin: "4.63vh auto 6.29vh auto"
            },
            title: {
                fontSize: "1.56vw",
                marginTop: "2.61vw"
            }
        },
        slider: {
            height: "40vw",
            title: {
                fontSize: "1.04vw",
                textAlign: "center" as "center",
                marginTop: "1.25vw",
                marginLeft: "2vw"
            },            
            sliderButton: {
                display: "block",
                top: "15.5vw"
            },
            card: {
                width: "44.74vw",
                height: "20.30vw",
                borderRadius: "1.04vw",
                marginTop: "1.25vw",
                marginBottom: "9.63vw",
                title: {
                    fontSize: "1.04vw",
                    width: "28.54vw",
                    height: "3vw",
                    overflowY: "hidden",
                    margin: "2.96vh auto 0 auto",
                    textAlign: "center" as "center",
                    hover: {
                        overflowY:"visible"
                    }
                },
                quoteIcon: {
                    width: "3.28vw",
                    height: "2.98vw",
                    margin: "-1.78vh 1.14vw 0 -4.48vw"
                },
                starWrapper: {
                    margin: "1.25vw 0 0 0",
                    textAlign: "center" as "center"
                },
                star: {
                    height: "0.70vw",
                    width: "0.9vw",
                    marginRight: "0.2vw"
                },
                description: {
                    margin: "1.25vw 1.67vw 0.86vw 1.67vw",
                    fontSize: "0.83vw",
                    lineHeight: 1.6,
                    maxHeight: '4vw',
                    overflowY: "hidden",
                    hover:{
                        overflowY:"visible"
                     }
                },
                userWrapper: {
                    textAlign: "inherit" as "inherit",
                    marginRight: "6vw",
                    marginTop: "3vw"
                },
                userLogo: {
                    width: "5.78vw",
                    height: "5.78vw",
                    border: "0.36vw solid " + Color.White,                    
                    borderRadius: "3.125vw"                    
                },
                userName: {
                    fontSize: "1.04vw",
                    marginTop: "0.74vw"
                },
                userJobTitle: {
                    fontSize: "0.83vw",
                    marginTop: "0.31vw"
                },
                organization: {
                    fontSize: "0.83vw",
                    marginTop: "0.31vw"
                },
                organizationLogoWrapper:{
                    margin: "2vw 1.51vw 2.04vh auto"
                },
                organizationLogo:{
                    width: "6.82vw",
                    height: "3.43vw",
                    marginTop:"0.64vw",
                    borderRadius: "0.78vw"
                },
                bottomWrapper: {
                    justifyContent: "center",
                    marginBottom: "16.67vh"
                }          
            },
            image_1: {
                display: "none"
            },
            dots: {
                margin: "0 2vw 1vw 0"
            },           
        }, 
        image_2: {
            display: "inline",
            marginLeft: "-3.6vw",
            marginTop: "0.3vw"
        },
        image_3: {
            display: "inline",
            marginRight: "-3.1vw",
            marginTop: "0.3vw"
        },
        imageWrapper: {
            width: "22.91vw",
            overflowX: "hidden",
            marginTop: "-3vw",
            overflowY: "display",
            zIndex: 2
        },
        topReviewGride: {
            display: "none"
        },
        topReviewSlider: {
            display: "block",
            height:"28vw",
            margin: "4.63vh -25% 8vw 7.2vw",
            width:"171.67vw"
        },
        sliderButtonWrapper: {
            display: "flex"
        },
        reviewTitle: {
            fontSize: "1.04vw",
            margin: "10.46vh 5% 0.74vh 7.2vw"
        },
        reviewCard:{   
            width: "27.91vw",
            height: "22.39vw",    
            borderRadius: "1.04vw",
            border: "0.21vw solid " + Color.AliceBlue, 
            margin: "0 0.83vw 6.48vh 0",
            title: {
                maxHeight: "3.2vw",
                fontSize: "1.04vw",
                margin: "3.70vh 0 0 2.08vw",
                lineHeight: 1.5,
                overflowY:"hidden",
                hover: {
                    overflowY:"auto"
                }
            },
            quoteIcon: {
                width: "5.60vw",
                height: "5.08vw",
                margin: "1.87vh 1.22vw 0 auto"  
            },
            starWrapper: {
                margin: "0.5vw 0 0 2.08vw"
            },
            star: {
                marginRight: "0.15vw",
                height: "0.70vw",
                width: "0.9vw"
            },
            description: {
                margin: "0.92vh 2.08vw 0 2.08vw",
                fontFamily: "Poppins",
                fontSize: "0.94vw",
                fontWeight: "medium" as "medium",
                color: Color.SharpAsh,
                maxHeight: "12vw",
                overflowY: "hidden",
                hover:{
                    overflowY:"auto"
                }
            },
            userWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                margin: "2.04vw 0 3.52vh 2.08vw",
                width: "16vw"
            },
            userLogo: {
                width: "3.125vw",
                height: "3.125vw",                
                borderRadius: "1.562vw"            
            },
            userName: {
                height: "2.31vh",
                fontSize: "0.94vw",
                marginLeft: "0.78vw",
                overflow: "hidden"
            },
            userJobTitle: {
                height: "2.12vh",
                fontSize: "0.83vw",
                margin: "0 0 0 0.78vw",
                overflow: "hidden"
            },
            organizationLogoWrapper:{
                margin: "unset",
                height: "unset"  
            },
            organizationLogo:{
                width: "6.82vw",
                height: "3.43vw",
                borderRadius: "0.78vw",
                margin: "2.04vw 0 3.52vh auto"
            }        
        },
        dots:{
            marginRight: "25vw",
            marginLeft: "-60vw"
        }
    }
}