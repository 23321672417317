import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const candidateProfilePage = {
    mobile: {
        baseWrapper: {
            display: "flex",
            flexDirection: "column",
            margin: "3.62vw 4.83vw 24.15vw"
        },
        leftWrapper: {
            width: "100%",
            flexShrink: "0"
        },
        rightWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "19.947vw",
            marginLeft: "1.61vw",
            textAlign: "center",
            flexShrink: "0"
        },
        personalDetails: {
            display: "flex",
            flexDirection: "column",
            color: Color.MiddleBlack,
            imageWrapper: {
                display: "flex",
                flexDirection: "row",
                position: "relative",
            },
            detailWrapper: {
                marginLeft: "0",
            },
            shareWrapper: {
                display: "flex",
                flexDirection: "row",
                marginTop: "3.90vh",
                fontSize: "3.86vw",
                justifyContent: "center",
                alignItems: "center",
                color: Color.MiddleBlack
            },
            imageHolder: {
                width: "24.15vw",
                height: "24.15vw",
                borderRadius: "2.41vw",
                overflow: "hidden",
                marginTop: "-7vh",
                position: "relative",
            },
            fileInput: {
                display: "none"
            },
            candidateImage: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
            },
            cameraIconWrapper: {
                position: "absolute",
                bottom: "1.20vw",
                right: "1.20vw",
                backgroundColor: Color.White,
                width: "6.03vw",
                height: "6.03vw",
                borderRadius: "50%",
                cursor: "pointer",
                WebkitTapHighlightColor: "transparent",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0"
            },
            cameraIcon: {
                fill: Color.MiddleBlack,
                width: "2.89vw",
                height: "2.17vw"
            },
            uploadImagePopup: {
                container: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50%"
                }
            },
            uploadedImage: {
                display: "block",
                width: "80%",
                height: "80%",
                objectFit: "cover",
                borderRadius: "2.42vw",
                margin: "auto",
                border: "2px solid " + Color.MiddleAsh
            },
            uploadImageButton: {
                border: "none",
                color: Color.White,
                width: "100%",
                height: "5.58vh",
                borderRadius: "2.41vw",
                fontSize: "4.34vw",
                fontWeight: "bold" as "bold",
                textTransform: "uppercase",
                cursor: "pointer",
                backgroundColor: Color.Blue
            },
            settingsButton: {
                width: "28.26vw",
                border: "0.483vw solid " + Color.Blue,
                color: Color.Blue,
                background: "transparent",
                fontWeight: "bold",
                fontSize: "3.623vw",
                textTransform: "uppercase",
                borderRadius: "1.2vw",
                height: "8.45vw",
                margin: "0",
            },
            premiumButton: {
                width: "33.09vw",
                border: "none",
                position: "absolute",
                backgroundColor: Color.HarvestGold,
                borderRadius: "1.21vw",
                textTransform: "uppercase",
                height: "4.02vh",
                marginTop: "0",
                fontWeight: "bold",
                fontSize: "3.62vw",
                color: Color.White,
                top: "-14.49vw",
                right: "0",
                icon: {
                    width: "4.35vw",
                    height: "1.56vh",
                    fill: Color.White,
                    marginRight: "2.42vw"
                }
            },
            crownWrapper: {
                display: "flex",
                width: "4.35vw",
                height: "4.34vw",
                backgroundColor: Color.HarvestGold,
                borderRadius: "50%",
                marginLeft: "1.45vw",
                alignItems: "center",
                justifyContent: "center"
            },
            crownIcon: {
                width: "2.64vw",
                fill: Color.White                
            },
            premiumMessage: {                                
                position: "absolute",
                width: "85%",
                right: "unset",
                top: "unset",
                padding: "1.82vw",
                marginTop: "2.41vw",
                fontSize: "3.38vw",
                border: "1px solid " + Color.Gray,
                borderRadius: "1vw",
                boxShadow: "3px 3px 10px #00000029",
                backgroundColor: Color.White,
                zIndex: "1",
                pointer: {
                    position: "absolute",
                    width: "2.8vw",
                    height: "2.8vw",
                    top: "-1.8vw",
                    right: "unset",
                    left: "20%",
                    transform: "translateX(-50%) rotate(45deg)",                    
                    backgroundColor: Color.White,
                    borderTop: "1px solid " + Color.Gray,
                    borderLeft: "1px solid " + Color.Gray,
                    boxShadow: "-5px -5px 10px #00000029",
                }
            },
            storeButton: {
                display: "flex",
                width: "28.26vw",
                height: "8.45vw",                
                border: "none",
                backgroundColor: Color.Blue,
                borderRadius: "1.21vw",                             
                fontWeight: "bold",
                fontSize: "3.62vw",
                color: Color.White,
                margin: "0 2.41vw 0 7.24vw",                
                alignItems: "center",
                justifyContent: "center",
                icon: {
                    width: "3.94vw",
                    height: "3.94vw",
                    marginRight: "2.41vw"
                },
                dropdown: {
                    position: "absolute",
                    width: "56.52vw",
                    height: "auto",
                    margin: "auto",
                    padding: "1.38vw",
                    backgroundColor: Color.White,
                    zIndex: 1,
                    boxShadow: "5px 6px 20px #00000027",
                    right: "0",
                    top: "9.85vw",
                    border: "1px solid " + Color.LightSilver,
                    borderRadius: "2.41vw",
                    textAlign: "left",
                    lineHeight: "normal"
                },
                dropdownLabel: {
                    display: "block",
                    position: "relative" as "relative",
                    textDecoration: "none",
                    fontWeight: "medium",
                    letterSpacing: "0px",
                    color: Color.MiddleBlack,
                    cursor: "pointer",
                    opacity: "1",
                    textTransform: "capitalize",
                    fontSize: "3.62vw",
                    backgroundColor: Color.White,
                    padding: "1.38vw",
                    hover: {
                        backgroundColor: Color.LightBlue,
                        borderRadius: "1.69vw"
                    }
                },
                bottomBorder: {
                    border: 0,
                    height: "1px",
                    width: "92%",
                    background: Color.MiddleAsh,
                    margin: "0 auto",
                    zIndex: "1"
                },
                hoveredBorder: {
                    background: Color.LightBlue,
                    borderRadius: "1px"
                }
            },
            name: {
                display: "flex",
                fontWeight: "bold",
                color: Color.Black,
                fontSize: "3.86vw",
                margin: "2.41vh 0 0 0"
            },
            jobTitle: {
                color: Color.Black,
                fontSize: "3.62vw",
                marginTop: "0.56vh",
            },
            location: {
                display: "flex",
                fontSize: "2.65vw",
                marginTop: "1.72vh",
                alignItems: "center"
            },
            lastUpdate: {
                display: "flex",
                fontSize: "2.66vw",
                marginTop: "1.23vh",
                alignItems: "center"
            },
            icon: {
                height: "3.14vw",
                width: "3.14vw",
                fill: Color.MiddleBlack,
                marginRight: "1.21vw",
            },
            bold: {
                fontWeight: "bold"
            },
            summaryTitle: {
                fontWeight: "bold",
                color: Color.LightBlack,
                fontSize: "3.86vw",
                marginTop: "6.16vw",
                marginBottom: "0"
            },
            summary: {
                fontSize: "3.38vw",
                marginTop: "3.62vw",
            },
            readMore: {
                fontWeight: "bold",
                color: Color.Blue
            },
            line: {
                display: "none",
            },
            mobileProfileLevelIndicator: {
                wrapper: {
                    display: "flex",
                    marginTop: "4.46vh",
                    background: Color.Nyanza,
                    border: "3px solid " + Color.Green,
                    borderRadius: "2.42vw",
                    alignItems: "center",
                    order: "1",
                    padding: "4.83vw"
                },
                level: {
                    width: "30%",                    
                    marginLeft: "4.83vw",
                    flexShrink: "0",
                },
                text: {
                    color: Color.LightBlack,
                    fontSize: "3.86vw",
                    marginLeft: "4.83vw",
                },
                link: {
                    fontWeight: "bold",
                    color: Color.Green,
                    fontSize: "3.62vw",
                    textDecoration: "underline",
                    lineHeight: "3.91vh",
                    cursor: "pointer",
                },
            },
            shareText: {
                margin: "0",
                fontSize: "3.86vw"
            },
            cvExpandWrapper: {
                display: "block",
            },
            cvExpand: {
                display: "flex",
                flexDirection: "row",
                height: "5.02vh",
                marginTop: "1.90vh",
                paddingLeft: "3.62vw",
                paddingRight: "3.62vw",
                lineHeight: "5.02vh",
                border: "none",
                borderRadius: "1.21vw",
                backgroundColor: Color.LightAsh,
                color: Color.Black,
                fontSize: "3.86vw",
                fontWeight: "500",
                cursor: "pointer",
                WebkitTapHighlightColor: "transparent",
                transition: "border-radius .2s ease-out",
                expanded: {
                    borderRadius: "1.21vw 1.21vw 0 0"
                }
            },
            cvExpandIcon: {
                height: "1.79vh",
                margin: "auto",
                marginRight: "0",
                transition: "transform .2s ease-in-out",
                expanded: {
                    transform: "rotate(180deg)"
                }
            },
            cvPreview: {
                overflow: "hidden",
                width: "-webkit-fill-available",
                rowWrapper: {
                    display: "flex",
                    flexDirection: "row",
                },
                link: {
                    display: "none"
                },
                icon: {
                    marginLeft: "auto",
                    fill: Color.Black,
                    width: "3.62vw",
                },
                text: {
                    display: "none"
                },
                viewCV: {
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center"
                },
                viewCVIcon: {
                    marginLeft: "2.41vw",
                    stroke: Color.White
                },
                holder: {
                    position: "relative" as "relative",
                    height: "60.38vw",
                    border: "1px solid " + Color.MiddleAsh,
                    borderRadius: "2.41vw",
                    overflow: "hidden"
                },
                layer: {
                    position: "absolute" as "absolute",
                    bottom: "0",
                    left: "0",
                    top: "0",
                    right: "0",
                    background: "transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box"
                },
                label: {
                    position: "absolute" as "absolute",
                    bottom: "5%",
                    left: "5%",
                    fontSize: "14px",
                    color: Color.White,
                    textTransform: "lowercase",
                    letterSpacing: "0px"
                },
                name: {
                    display: "none"
                },                
                documentViewer: {
                    height: "100%"
                }
            },
            cvWrapper: {
                display: "flex",
                padding: "4.83vw 4.83vw 7.25vw 4.83vw",
                borderRadius: "0 0 2.41vw 2.41vw",
                border: "1px solid " + Color.LightSilver,
                borderTop: "none",
                right: {
                    marginLeft: "3.62vw"
                }
            },
            uploadCV: {
                height: "29.47vw",
                width: "33.09vw",
                border: "1px dashed " + Color.DarkGray,
                borderRadius: "2.41vw",                
                marginTop: "11.35vw",
                cursor: "pointer",                
                WebkitTapHighlightColor: "transparent"
            },
            uploadCVTexts: {
                fontSize: "3.38vw",
                color: Color.SharpAsh,
                textAlign: "left",
                width: "23.18vw",
                lineHeight: "3.62vw"
            },
            uploadCVMaxFileSize: {
                fontSize: "2.41vw",
                margin: "1.11vh 0 0 0"
            },
            uploadIconWrapper: {
                backgroundColor: Color.LightAsh,
                width: "9.66vw",
                height: "9.66vw",
                borderRadius: "50%",
                margin: "auto auto 1.11vh 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            uploadIcon: {
                width: "3.14vw",
                height: "auto"
            },
            uploadedCVName: {
                margin: "2.79vh 0 0.55vh 0",
                fontSize: "3.86vw",
                color: Color.MiddleBlack,
                textTransform: "lowercase"
            },
            viewCV: {
                link: {
                    fontSize: "2.90vw",
                    color: Color.Blue
                },
                icon: {
                    height: "2.41vw",
                    width: "2.41vw",
                    stroke: Color.Blue,
                    marginLeft: "0.97vw"
                },
                popup:{
                    justifyContent: "center",
                    container: {
                        width: "90.33vw",
                        height: "166.67vw",
                        borderRadius: "2.41vw",
                        padding: "2.41vw"
                    },
                    closeButton: {
                        width: "3.86vw",
                        height: "3.86vw",
                        top: "1.93vw",
                        right: "1.93vw"
                    }
                },
                documentViewer: {
                    height: "100%"
                }
            }
        },
        desktopProfileLevelIndicator: {
            wrapper: {
                display: "none",
                background: Color.Nyanza,
                border: "0.15vw solid " + Color.Green,
                borderRadius: "0.52vw",
                alignItems: "center",
                padding: "1.04vw",
                marginBottom: "3.35vh"
            },
            level: {
                width: "10vw",
                height: "5vw",
                display: "flex"
            },
            text: {
                color: Color.LightBlack,
                fontSize: "0.833vw",
                margin: "0 0 0 1.06vw",
                fontWeight: "500",
                textAlign: "left"
            },
            link: {
                fontWeight: "bold",
                color: Color.Green,
                fontSize: "0.833vw",
                textDecoration: "underline",
                lineHeight: "4vh",
                cursor: "pointer"
            },
        },
        cvPreviewer: {
            overflow: "hidden",
            rowWrapper: {
                display: "flex",
                flexDirection: "row",
                margin: "1.85vh 1.04vw"
            },
            link: {
                marginLeft: "auto",
                textDecoration: "none",
                color: Color.Blue,
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                fontSize: "0.729vw"
            },
            icon: {
                marginLeft: "0.52vw",
                stroke: Color.Blue,
                width: "0.73vw",
                height: "0.73vw"
            },
            text: {
                color: Color.Black,
                fontWeight: "bold",
                fontSize: "1.04vw"
            },
            viewCVIcon: {
                marginLeft: "2.41vw",
                stroke: Color.White
            },
            holder: {
                position: "relative" as "relative",
                border: "1px solid " + Color.MiddleAsh,
                borderRadius: "0.56vw",
                height: "26.71vw",
                overflow: "hidden"
            },
            layer: {
                position: "absolute" as "absolute",
                bottom: "0",
                left: "0",
                top: "0",
                right: "0",
                background: "transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box"
            },
            label: {
                position: "absolute" as "absolute",
                color: Color.White,
                textTransform: "lowercase",
                letterSpacing: "0px",
                left: "1.04vw",
                bottom: "1.04vw",
                fontSize: "0.937vw"
            },
            name: {
                marginBottom: "7px",
                position: "absolute" as "absolute",
                left: "1.04vw",
                bottom: "1.04vw",
                fontSize: "0.93vw",
                color: Color.White
            },
            popup:{
                justifyContent: "center",
                container: {
                    width: "90.33vw",
                    height: "166.67vw",
                    borderRadius: "2.41vw",
                    padding: "2.41vw"
                },
                closeButton: {
                    width: "3.86vw",
                    height: "3.86vw",
                    top: "1.93vw",
                    right: "1.93vw"
                }
            },            
            documentViewer: {
                height: "100%"
            },
            addDocument: {
                height: "92%"
            },
            addCVButton: {
                border: "none",
                color: Color.White,
                width: "100%",
                height: "5.58vh",
                borderRadius: "2.41vw",
                fontSize: "4.34vw",
                fontWeight: "bold" as "bold",
                textTransform: "uppercase",
                cursor: "pointer",
                backgroundColor: Color.Blue,
                marginTop: "2%"
            }
        },
        tabs: {
            container: {
                marginTop: "5.58vh",
            },
            linkWrapper: {
                display: "flex",
                flexDirection: "row",
                backgroundColor: Color.LightAsh,
                margin: "0 -4.83vw 0 0"
            },
            link: {
                flexShrink: "0",
                fontSize: "3.86vw",
                color: Color.DeepAsh,
                borderRadius: "1.20vw",
                padding: "2.65vw",
                marginRight: "2.42vw",
                alignSelf: "center",
                cursor: "pointer",
                active: {
                    backgroundColor: Color.DeepBlack,
                    color: Color.LightAsh,
                    position: "sticky",
                    right: "0",
                    left:"0"
                },
                WebkitTapHighlightColor: "transparent"
            },
            contentWrapper: {},
        },
        saveWrapper: {
            marginBottom: "21.74vw"
        },
        horizontalBanner: {
            margin: "0 4.83vw 24.15vw 4.83vw"            
        },
        uploadCV: {
            width: "17.97vw",
            height: "4.68vw",
            border: "1px dashed " + Color.DarkGray,
            borderRadius: "0.52vw",            
            marginTop: "2.31vh",
            display: "flex",            
            cursor: "pointer"
        },
        uploadCVTexts: {
            fontSize: "0.93vw",
            color: Color.SharpAsh,
            marginLeft: "0.78vw",
            fontFamily: Font.Poppins,
            textAlign: "left"
        },
        uploadCVMaxFileSize: {
            fontSize: "0.72vw",
            margin: "0.27vh 0 0 0",
            fontFamily: Font.Poppins
        },
        uploadIconWrapper: {
            backgroundColor: Color.LightAsh,
            width: "2.6vw",
            height: "2.6vw",
            borderRadius: "50%"
        },
        uploadIcon: {
            width: "0.83vw",
            height: "0.83vw"
        },        
        noResults: {
            textAlign: "center" as "center",
            color: Color.SharpAsh,
            fontSize: "5vw",
            marginTop: "5vh",
            zero: {
                fontWeight: "bold",
                color: Color.DeepBlack,
            },
            wrapper: {
                borderRadius: "2.77vw",
                border: "1px solid " + Color.HarvestGold,
                backgroundColor: Color.LightGold,
                display: "flex",
                alignItems: "center" as "center",
                justifyContent: "center" as "center",
                padding: "2.41vh",
                color: Color.HarvestGold,
                margin: "1.5vh 5% 4vh 5%"
            },
            icon: {
                marginRight: "5.43vw",
                width: "8.33vw",
                height: "8.33vw"
            }
        },
        verticalBanner: {                                       
            marginTop: "3.125vw",
            marginBottom: "9.90vw",
            width: "100%"         
        }
    },
    desktop: {
        baseWrapper: {
            flexDirection: "row",
            margin: "5.27vh 7.29vw 0 7.29vw"
        },
        leftWrapper: {
            width: "63.85vw"
        },
        personalDetails: {
            flexDirection: "row",
            imageWrapper: {
                flexDirection: "column"
            },
            detailWrapper: {
                marginLeft: "2.34vw"
            },
            shareCVSwitch: {
                switch: {
                    width: "1.718vw",
                    height: "unset",
                    marginLeft: "1.04vw"
                },
                switchCircle: {
                    width: "0.729vw",
                    height: "0.729vw"
                }
            },
            shareWrapper: {
                width: "15vw",
                position: "absolute",
                right: "28.375vw",
                marginTop: "0"
            },
            imageHolder: {
                width: "10.15vw",
                height: "10.15vw",
                marginTop: "-8vw",
                borderRadius: "0.52vw"
            },
            cameraIconWrapper: {
                width: "1.56vw",
                height: "1.56vw",
                right: "0.52vw",
                bottom: "0.52vw"
            },
            cameraIcon: {
                width: "1vw",
                height: "0.57vw"
            },
            uploadImagePopup: {
                container: {
                    width: "20vw",
                    height: "20vw",
                    bottom: "unset"
                }
            },
            uploadedImage: {
                height: "80%",
                width: "70%",
                borderRadius: "0.52vw"
            },
            uploadImageButton: {
                height: "5vh",
                borderRadius: "0.48vw",
                fontSize: "0.83vw"
            },
            premiumButton: {
                position: "unset",
                width: "10.16vw",
                height: "2.60vw",
                margin: "0",
                borderRadius: "0.52vw",
                fontSize: "0.833vw",
                icon: {
                    width: "1.04vw",
                    height: "1.48vh",
                    marginRight: "0.52vw"
                }
            },
            crownWrapper: {
                width: "1.77vw",
                height: "1.77vw",
                marginLeft: "0.83vw"
            },
            crownIcon: {
                width: "1.04vw",
                height: "1.48vh"
            },
            premiumMessage: {
                width: "auto",
                top: "unset",
                bottom: "unset",
                right: "unset",
                borderRadius: "0.6vw",                
                padding: "0.625vw",
                marginTop: "0.625vw",
                fontSize: "0.83vw",
                pointer: {
                    top: "-0.4vw",
                    bottom: "unset",
                    width: "0.7vw",
                    height: "0.6vw"
                }
            },
            settingsButton: {
                width: "10.16vw",
                height: "2.60vw",
                margin: "1.38vh 0 1.38vh 0",
                borderRadius: "0.52vw",
                fontSize: "1.04vw",
                border: "3px solid " + Color.Blue,
            },
            storeButton: {
                display: "none"
            },
            name: {
                fontSize: "1.56vw",
                margin: "0"
            },
            jobTitle: {
                fontSize: "1.04vw",
                marginTop: "0.93vh"
            },
            location: {
                fontSize: "0.83vw",
                marginTop: "1.85vh"
            },
            lastUpdate: {
                fontSize: "0.83vw"
            },
            icon: {
                height: "0.937vw",
                width: "0.937vw",
                marginRight: "0.62vw"
            },
            summaryTitle: {
                fontSize: "1.04vw",
                marginTop: "2.60vw"
            },
            summary: {
                width: "49.48vw",
                fontSize: "0.83vw",
                marginTop: "1.56vw"
            },
            line: {
                display: "block",
                height: "0.09vh",
                color: Color.Gainsboro,
                backgroundColor: Color.Gainsboro,
                marginTop: "4.63vh",
                marginBottom: "0.00vh",
                border: "none",
            },
            shareText: {
                fontSize: "0.833vw"
            },
            mobileProfileLevelIndicator: {
                wrapper: {
                    display: "none"
                }
            }
        },
        desktopProfileLevelIndicator: {
            wrapper: {
                display: "flex"
            }
        },
        tabs: {
            container: {
                marginTop: "4.62vh"
            },
            linkWrapper: {
                width: "fit-content",
                borderRadius: "0.52vw",
                margin: "0 3.12vw 0 0",
            },
            link: {
                fontSize: "0.93vw",
                borderRadius: "0.52vw",
                padding: "0.67vw 1.562vw",
                marginRight: "0",
                active: {
                    borderRadius: "0.52vw"                    
                }
            },
            title: {
                fontSize: "1.56vw",
                marginTop: "4.63vh"
            },
        },
        saveWrapper: {
            marginBottom: "7.81vw"
        },
        horizontalBanner: {
            display: "none"
        },
        noResults: {            
            fontSize: "1.3vw",
            marginTop: "13.6vh",
            wrapper: {
                width: "75%",
                borderRadius: "0.7vw",
                margin: "3.2vh auto 7.81vw auto"
            },
            icon: {
                marginRight: "1.43vw",
                width: "2.19vw",
                height: "2.19vw"
            }
        },
        cvPreviewer: {
            width: "100%",
            popup:{
                container: {                    
                    width: "30.99vw",
                    height: "43.85vw",                    
                    borderRadius: "0.52vw",
                    padding: "0.83vw"
                },
                closeButton: {                    
                    width: "0.94vw",
                    height: "0.94vw",
                    top: "0.21vw",
                    right: "0.21vw"
                }
            },
            addDocument: {
                height: "91%"
            },
            addCVButton: {
                height: "6.48vh",
                borderRadius: "0.52vw",
                fontSize: "1.04vw"
            }
        }
    }
};
