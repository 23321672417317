import React, { useRef, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import ResizeModule from "@botom/quill-resize-module";
import 'quill/modules/history';
import { ReactComponent as Undo } from '../../assets/svg/undo.svg';
import { ReactComponent as Redo } from '../../assets/svg/redo.svg';


interface Props {
    value?: string;
    onChange(content: any): void;
    styles?: any;
}

export const QuillEditor: React.FC<Props> = (props) => {
    const editorRef = useRef<any>(null);
    const quillRef = useRef<any>(null);
    const toolbarRef = useRef<any>(null);

    Quill.register("modules/resize", ResizeModule);

    useEffect(() => {
        quillRef.current = new Quill(editorRef.current, {
            theme: 'snow',
            modules: {
                toolbar: {
                    container: toolbarRef.current,
                    handlers: {
                        'undo': undo,
                        'redo': redo,
                        'image': () => handleImageUpload()
                    }
                },
                resize: {
                    locale: {}
                },
                history: {
                    delay: 1000,
                    maxStack: 500,
                    userOnly: true
                },
                imageResize: true,
            }
        });

        quillRef.current.on('text-change', () => {
            props.onChange(quillRef.current.root.innerHTML);
        });

        if (props.value) {
            quillRef.current.root.innerHTML = props.value;
        }
    }, []);

    const undo = () => {
        quillRef.current.history.undo();
    };
    
    const redo = () => {
        quillRef.current.history.redo();
    };

    const handleImageUpload = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/png, image/jpeg');
        input.onchange = () => {
            const file = input.files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const range = quillRef.current.getSelection(true);
                    const base64 = reader.result;
                    quillRef.current.insertEmbed(range.index, 'image', base64);
                    quillRef.current.setSelection(range.index + 1);
                };
                reader.readAsDataURL(file);
            }
        };
        input.click();
    };

    return (
        <div style={props.styles}>
            <div ref={toolbarRef}>
                <div className='iconWrapper'>
                    <span className="ql-formats">
                        <select className="ql-font"></select>
                        <select className="ql-size"></select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-bold"></button>
                        <button className="ql-italic"></button>
                        <button className="ql-underline"></button>
                        <button className="ql-strike"></button>
                    </span>
                    <span className="ql-formats">
                        <select className="ql-color"></select>
                        <select className="ql-background"></select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-script" value="sub"></button>
                        <button className="ql-script" value="super"></button>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-header" value="1"></button>
                        <button className="ql-header" value="2"></button>
                        <button className="ql-blockquote"></button>
                        <button className="ql-code-block"></button>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-list" value="ordered"></button>
                        <button className="ql-list" value="bullet"></button>
                        <button className="ql-indent" value="-1"></button>
                        <button className="ql-indent" value="+1"></button>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-direction" value="rtl"></button>
                        <select className="ql-align"></select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-link"></button>
                        <button className="ql-image"></button>
                        <button className="ql-video"></button>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-undo"><Undo /></button>
                        <button className="ql-redo"><Redo /></button>
                    </span>
                </div>
            </div>
            <div ref={editorRef}></div>
        </div>
    );
}