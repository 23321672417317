import { useEffect, useState } from "react";
import { ResponseAlert } from "../components/Shared/ResponseAlert";

export const useAlert = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [status, setStatus] = useState<string>();
    const [message, setMessage] = useState<string>();
    const duration = 8000;
    
    useEffect(() => {
        const interval = setInterval(() => {
            setIsOpen(false);
        }, duration);
        return () => clearInterval(interval);
    }, []);

    const openAlert = (status: string, message:string) => {
        setIsOpen(true); 
        setStatus(status); 
        setMessage(message);  
    }
    const Alert = () => <ResponseAlert isOpen={isOpen} status={status} message={message}/>;
  
    return {Alert, openAlert};
};