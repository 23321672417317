import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { ReactComponent as CheckMark } from '../../assets/svg/checkmark.svg';
import { DropdownItem } from '../Shared/DropdownItem';
import { Option } from '../../models/SelectInputs';
import { Sector } from '../../models/Sector';

interface Props {
    styles: any;
    sectors: Sector[];
    selectOption(sectorId: number): void;
}

export const SectorSelect: React.FC<Props> = (props) => {
    const styles = props.styles;
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [options, setOptions] = useState<Option[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        generateSectorOptions();
    }, [props.sectors])

    const filterOptions = (keyword: string) => {
        setSearchKeyword(keyword);
        var tempOptions = options.filter(x => x.option.toUpperCase().includes(keyword.toUpperCase()));
        setFilteredOptions(tempOptions);
    }

    const generateSectorOptions = () => {
        let tempOptions: Option[] = [];
        props.sectors.forEach((sector) => {
            let option: Option = {
                value: sector.sectorId,
                option: sector.sectorName,
                checked: sector.isSelected
            }
            tempOptions.push(option);
        })
        setOptions(tempOptions);
        setFilteredOptions(tempOptions);
    }

    const focusInput = (event: React.MouseEvent) => {
        event.stopPropagation()
        if (inputRef.current) {
          inputRef.current.focus();
        }
    };

    return (
        <div style={styles.wrapper}>
            <input type="text" ref={inputRef} style={styles.search} placeholder={"Search"} onClick={e => {e.stopPropagation()}} onChange={(e) => filterOptions(e.target.value)} />
            {searchKeyword === "" && <SearchIcon style={styles.search.icon} onClick={(e) => focusInput(e)} />}
            <div className="scrollArea optionSelectScroll" style={styles.scrollArea}>
                {filteredOptions.map((option, index) =>
                    <div
                        key={index}
                        onMouseEnter={() => { setHoveredIndex(index); }}
                        onMouseLeave={() => { setHoveredIndex(-1); }}
                    >
                        <DropdownItem key={index} styles={styles.option} onClick={() => props.selectOption(option.value as number)}>
                            {option.checked ? <CheckMark style={styles.checkMark} />
                                : <div style={styles.checkbox}></div>}
                            <label style={styles.optionLabel}>{option.option}</label>
                        </DropdownItem>
                        <hr style={{ ...styles.bottomBorder, ...((hoveredIndex === index || hoveredIndex === index + 1 || filteredOptions.length === index + 1) && styles.hoveredBorder) }} />
                    </div>
                )}
            </div>
        </div>
    );
}