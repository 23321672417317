import React, { useState } from 'react'
import { DocumentViewer } from '../Shared/DocumentViewer';
import { ReactComponent as ExternalLink } from '../../assets/svg/external-link.svg';
import { PopUpWindow } from '../Shared/PopUpWindow';

interface Props {
    styles: any;    
    cvFile?: string | File;
    cvFileName: string;    
}

export const CVPreviewer: React.FC<Props> = (props) => {
    let styles = props.styles;
    const [openCVPopup, setOpenCVPopup] = useState<boolean>(false);

    return (
        <>
            <div style={styles}>
                <div style={styles.rowWrapper}>
                    <label style={styles.text}>My CV</label>
                    <div style={styles.link} onClick={() => setOpenCVPopup(true)}>
                        View CV <ExternalLink style={styles.icon} />
                    </div>
                </div>
                <div style={styles.holder}>
                    <div style={styles.layer}></div>
                    <p style={styles.name}>{props.cvFileName}</p>
                    <DocumentViewer styles={styles.documentViewer} document={props.cvFile}/>
                </div>
            </div>
            <PopUpWindow styles={styles.popup} open={openCVPopup} toggle={() => setOpenCVPopup(!openCVPopup)} closeCircle={true}>
                <DocumentViewer styles={styles.documentViewer} document={props.cvFile}/>                
            </PopUpWindow>            
        </>
    )
}
