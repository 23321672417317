import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const footer = {
    mobile: {
        background: Color.DeepBlack,
        width: "100%",    
        marginTop: "24.15vw",        
        color: Color.White,
        letterSpacing: "0px",      
        opacity: "1",
        position: "relative" as "relative",
        overflow: "hidden",
        leftSvg: {
            display: "none",
            position: "absolute" as "absolute",
            width: "36vw",
            height: "45vh",
            bottom: "0",
            transform: "translate(0, 2vh)"
        },
        rightSvg: {
            position: "absolute" as "absolute",
            width: "83%",
            height: "22%",
            bottom: "-1.3vw",
            right: "0"
        },
        wrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            textDecoration: "none",
            color: Color.White
        },  
        subWrapper: {
            display:"flex",
            flexDirection:"column",
            gap: "2.89vw"
        },      
        title: {
            margin: "12.07vw 0 8.21vw 5.31vw",
            width: "58.93vw",
            height: "13.7vw"
        },
        details: {
            position: "relative" as "relative",
            margin: "0 0 0 5.31vw",
            display: "grid",            
            gap: "2.89vw",            
            fontSize: "3.33vw",
            color: Color.LightAsh    
        },
        largeIcon: {                
            cursor: "pointer",
            fill : Color.White,
            WebkitTapHighlightColor : "transparent"
        },
        smallIcon: {                
            width: "2.77vw",
            height: "2.77vw",
            marginTop: "0.4vh",
            marginRight: "2.22vw",
            fill : Color.White
        },
        newsletter: { 
            margin: "7.97vw 0 0 4.83vw",
            position: "relative",
            heading: {
                fontSize: "5.55vw",
                fontWeight: "bold",
                color: Color.White
            },
            text: {                
                fontSize: "3.33vw",
                color: Color.LightAsh
            }
        },
        searchArea: {        
            position: "relative" as "relative",          
            width: "83.09vw", 
            marginLeft:"auto",
            marginRight:"auto",
            borderRadius: "2.41vw",
            backgroundColor: Color.White, 
            overflow:"hidden",
            height: "21.73vw", 
            padding: "4.83vw 3.62vw 3.62vw 3.62vw",
            input: {
                height:"5.55vw",
                width: "100%",
                fontSize: "4.16vw",
                border: "none",
                color: Color.SharpAsh
            },
            button: {
                position: "absolute" as "absolute", 
                right: "3.62vw",
                left: "3.62vw",
                bottom: "3.62vw",
                width: "83.09vw",
                height: "12.07vw",                    
                color: Color.White,
                fontWeight: "bold" as "bold",
                backgroundColor: Color.Green,
                border: "none",
                borderRadius: "2.41vw",
                fontSize: "4.34vw",
                buttonIcon: {
                    display: "none"
                }
            }
        },
        menu: { 
            position: "relative" as "relative",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            paddingTop: "8%",
            paddingLeft: "8%", 
            menuItem: {                                                     
                letterSpacing: "0px",
                opacity: "1",                
                label: {         
                    fontSize: "4.44vw",  
                    fontWeight: 500,
                    marginRight: "3.33vw",
                    marginBottom: "2.41vw",
                    color: Color.White                      
                },
                list: {
                    listStyleType: "none",
                    marginTop: "0%",
                    paddingLeft: "5%"
                },
                item: {
                    textDecoration: "none", 
                    display:"inline-block", 
                    fontSize: "3.62vw",
                    marginBottom: "0.26vw",
                    color: Color.LightAsh,
                    whiteSpace: "nowrap",
                    WebkitTapHighlightColor : "transparent",
                    cursor: "pointer"
                }
            }                    
        },
        socialIconWrapper: {            
            marginBottom: "3.14vw",
            marginTop: "9.66vw"
        },
        copyright: {
            fontSize: "2.65vw", 
            color: Color.White,
            position: "relative",
            marginBottom: "14vw",
            marginLeft: "4.83vw"
        },
        curveSvg1: {
            position: "absolute" as "absolute",
            width: "85%",
            height: "75%",
            zIndex: "0",
            left: "30%"
        },
        curveSvg2: {
            position: "absolute" as "absolute",
            width: "100%",
            height: "80%",
            zIndex: "0",
            left: "25%",
            top: "-25%"
        },
        linkedIn:{
            width:"4.01vw",
            height:"4.01vw",
            marginLeft:"2.41vw"
        },
        whatsapp:{
            width:"4.10vw",
            height:"4.10vw",
            marginLeft:" 5.56vw"
        },
        facebook:{
            width:"1.89vw",
            height:"3.54vw",
            marginLeft:"5.56vw"
        },
        instagram:{
            width:"3.92vw",
            height:"3.92vw",
            marginLeft:"5.83vw"
        },
        twitter:{
            width:"3.64vw",
            height:"2.96vw",
            marginLeft:"5.49vw"
        }
    },
    desktop: {
        marginTop: "7.8vw",              
        height: "33.59vw",
        leftSvg: {
            display: "block"      
        },
        title: {            
            margin: "6.77vw 0 0 7.39vw",
            width: "15.52vw",
            height: "3.65vw"
        },
        rightSvg: {
            width: "17.5vw",
            bottom: "0",
            height: "auto"
        },
        details: {
            position: "absolute" as "absolute",
            display: "flex",
            fontSize: "0.93vw",
            gap: "unset",
            margin: "unset",
            top: "21.40vw",
            left: "48.28vw",
            width: "40vw",
            justifyContent: "space-between",
            alignItems: "center"
        },
        wrapper: {
            alignItems: "center"
        },
        subWrapper: {
            flexDirection: "row-reverse"
        },
        newsletter: { 
            width: "40%",
            margin: "1.30vw 0 1.56vw 7.39vw",
            heading: {
                fontSize: "1.56vw"
            },
            text: {
                width: "30vw",
                fontSize: "0.8vw",
                lineHeight: "1.17vw"
            }
        },        
        searchArea: {            
            width: "33.54vw", 
            marginTop: "0",
            marginLeft:"7.39vw",
            height: "1.35vw",
            paddingTop: "1.40vw",
            paddingBottom: "1.40vw",
            borderRadius: "0.52vw",
            paddingLeft: "1.56vw",
            paddingRight: "0",
            input: {
                width: "19.01vw",
                fontSize: "0.93vw",
                height: "1.30vw"
            },
            button: {
                position: "absolute" as "absolute",                
                right: "0.78vw",
                left: "unset",
                top: "0.78vw",                
                width: "13.54vw",
                height: "2.60vw",
                fontSize: "1.04vw",
                borderRadius: "0.52vw"
            }
        },
        menu: { 
            position: "absolute" as "absolute",            
            gridTemplateColumns: "repeat(3, 1fr)",   
            padding: "0",
            left: "48.28vw",
            top: "8.07vw",
            menuItem: {
                label: {
                    fontSize: "1.56vw",
                    marginRight: "8.22vw",
                    width: "8.22vw",
                    marginBottom: "unset"
                },
                item: {
                    fontSize: "0.93vw",
                    marginBottom: "0.26vw",
                    display: "block"
                },
                list: {
                    paddingLeft: "1.04vw",
                    marginTop: "0.26vw"
                }
            }
        },
        socialIconWrapper: {
            marginTop: "0",
            marginBottom: "1.562vw"
        },
        copyright: {
            marginBottom: "2.03vw",
            fontSize: "0.78vw",
            textAlign: "center" as "center",
            marginLeft: "0",
            position: "absolute",
            left: "0",
            right: "0",
            bottom: "0"
        },
        largeIcon: {
            width: "1vw",
            height: "1vw",
            marginLeft: "1vw"            
        },
        smallIcon: {
            width: "1.14vw",
            height: "auto",
            marginTop: "0",
            marginRight: "0.52vw"
        },
        locationIcon: {
            width: "1.14vw",
            height: "1.25vw"
        },
        phoneIcon: {
            width: "0.93vw",
            height: "0.93vw"
        },
        mailIcon: {
            width: "1.09vw",
            height: "0.729vw"
        },
        linkedIn:{
            width:" 0.86vw",
            height:" 0.86vw",
            marginLeft:"unset"
        },
        whatsapp:{
            width:"0.88vw",
            height:"0.88vw",
            marginLeft:"1.19vw"
        },
        facebook:{
            width:"0.40vw",
            height:"0.76vw",
            marginLeft:"1.19vw"
        },
        instagram:{
            width:"0.84vw",
            height:"0.84vw",
            marginLeft:"1.25vw"
        },
        twitter:{
            width:"0.78vw",
            height:"0.63vw",
            marginLeft:" 1.18vw"
        }
    }
}