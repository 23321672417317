import { Color } from "../enums/Color";

export const pagination = {
    mobile: {
        display: "flex",
        flexDirection: "row" as "row",
        width: "fit-content",
        margin: "9.66vw auto 0 auto",
        alignItems: "center" as "center",
        nextButton: {
            display: "flex",
            width: "9.66vw",
            height: "9.66vw",
            backgroundColor: Color.Blue,
            borderColor: Color.LightBlue,
            border: "1.21vw solid " + Color.SharpBlue,
            marginLeft: "8.45vw",
            borderRadius: "50%",
            cursor: "pointer",
            fill: Color.White,
            alignItems: "center",
            justifyContent: "center",
            padding: "1.45vw 1.69vw"
        },
        previousButton: {
            display: "flex",
            width: "7.24vw",
            height: "7.24vw",
            marginRight:"7.06vw",
            backgroundColor: Color.LightAsh,
            borderRadius: "50%",
            cursor: "pointer",
            fill: Color.SharpAsh,            
            alignItems: "center",
            justifyContent: "center",
            padding: "1.45vw 1.69vw"
        },
        button: {
            padding: "0 2.29vw",
            minWidth: "6.03vw",
            height: "6.03vw",
            marginLeft: "1.39vw",
            border: "none",
            borderRadius: "0.72vw",
            backgroundColor: "transparent",
            color: Color.DeepAsh,
            cursor: "pointer",
            fontSize: "2.89vw",
            active: {
                backgroundColor: Color.DeepBlack,
                color: Color.LightBlue
            }
        },
        dots: {
            marginLeft: "1.39vw"
        },
        arrowIcon: {
            width: "3.38vw",
            height: "2.41vw"
        }
    },
    desktop: {
        margin: "2.60vw auto 5vw auto",
        nextButton: {
            width: "3.6vw",
            height: "3.6vw",
            marginLeft: "1.56vw",            
            border: "0.5vw solid " + Color.SharpBlue,
            padding: "unset",
            lessMargin: {
                marginLeft: "0.86vw"
            }
        },
        previousButton: {
            width: "2.60vw",
            height: "2.60vw",
            marginRight: "0.86vw",
            padding: "unset",            
            lessMargin: {
                marginRight: "0.16vw"
            }            
        },
        button: {
            marginLeft: "0.70vw",
            padding: "0 0.70vw",
            minWidth: "1.82vw",
            height: "1.82vw",
            borderRadius: "0.36vw",
            fontSize: "0.78vw"
        },
        dots: {
            marginLeft: "0.70vw"
        },
        arrowIcon: {
            width: "0.83vw",
            height: "0.83vw"
        }
    }
}